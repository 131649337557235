/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import './Notfound.css';
import { Helmet } from 'react-helmet';

function Notfound() {
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  return (
    <main className="main-des">
      <Helmet>
        <title>Тест Бравермана - 404</title>
        <meta name="description" content="Страница не найдена" />
      </Helmet>
      <div className="container404">
        <h1 className="first-four">404</h1>
      </div>
      <div className="text404">
        <p className="wrong-para">О нет! :( Страница не найдена!</p>
        <div className="link404">
          <Link
            to="/"
          >
            Нажмите, что бы вернуться на главную страницу и пройти тест Бравермана
          </Link>
        </div>
      </div>
    </main>
  );
}
export default Notfound;
