/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useRef } from 'react';
import Acetilholinetable from './Content/Acetilholinetable';
import ResultChecker from './ResultChecker';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
// import BannerResult from '../../../components/banner/BannerResult';
import ShareBlock from '../../../components/shareblock/shareBlock';

function ResultAcetylcholinetwo() {
  showRedirectModal();
  const ref = useRef(null);
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Тест пройден
            <div className="result-endtest-textsmall">
              Теперь вы знаете ваш доминантный тип и дефицит нейромедиаторов.
              Ознакомьтесь с таблицей и сохраните себе результат
            </div>
          </div>
        </div>
        <ResultChecker />
        <div className="main-partone" ref={ref}>
          <div className="div-text-attention">По результатам теста у вас дефицит ацетилхолина</div>
          <div className="div-text">
            Ацетилхолин контролирует скорость работы вашего мозга, связывая ваши физические переживания с воспоминаниями и мыслями.
            <br />
            <br />
            Когда скорость вашего мозга снижается из-за дефицита ацетилхолина, то он не успевает связать все новое с ранее сохраненной информацией.
          </div>
          <div className="div-text-table">Для вас подготовлена таблица аминокислот и витаминов в соответствии с дефицитом нейромедиаторов.  </div>
          {/* <BannerResult /> */}
          <div className="div-text"><Acetilholinetable /></div>
          <div className="div-text">
            Ваша память может стать неравномерной, и вы можете не реагировать на сенсорные стимулы так же быстро, как и раньше. Это вызывает забывчивость.
            <br />
          </div>
        </div>
        <div>
          {/* <Link type="button" to="/pdf/Acetylholine.pdf" target="_blank" download className="div-text-save">СОХРАНИТЬ РЕЗУЛЬТАТ ТЕСТА</Link> */}
        </div>
        <ShareBlock urlProps="https://bnlab.ru/resultacetylcholinetwo" pdfSource="/pdf/Acetylholine.pdf" />
      </div>
    </main>
  );
}
export default ResultAcetylcholinetwo;
