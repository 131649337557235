/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useLayoutEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './ModalDesktopBanner.css';
import { DialogTitle } from '@mui/material';

// const HREF = 'https://stvkr.com/v2/click-bQvqp-ApMRY-7mzmx-17b19807?tl=1&sa=bnlab'; // old
// const HREF = 'https://stvkr.com/v2/click-bQvqp-ApMRY-7mzmx-17b19807?tl=1&sa=BNmainbanner';
const HREFDESKTOP = 'https://hvjjg.com/g/bp49co46zbee1d1931acf0b2a1952d/?erid=LatgBuq9Y?subid=desktop';
export default function ModalDesktopBanner({ open, setOpen }) {
  const handleToggle = () => {
    sessionStorage.setItem('banner', JSON.stringify(false));
    setOpen(false);
  };
  if (sessionStorage.getItem('banner') === JSON.stringify(true)) {
    return (
      <div>
        <Dialog
          className="modalbanner-main"
          open={open}
          // onClose={(handleToggle, 'backdropClick')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IoIosCloseCircleOutline
            className="madalbanner-closebutton"
            type="button"
            onClick={handleToggle}
          />
          {/* <DialogTitle id="alert-dialog-title">
            БАННЕР
          </DialogTitle> */}
          <DialogContent className="test">
            <div className="modalbanner-layout">
              <a
                href={HREFDESKTOP}
                target="_blank"
              >
                <img
                  className="modalbanner-image"
                  src="img/bannerDesktop.webp"
                  alt="bnlab"
                />
              </a>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
