/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const serotonineSlice = createSlice({
  name: 'serotonine',
  initialState,
  reducers: {
    serotonineslice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { serotonineslice } = serotonineSlice.actions;

export default serotonineSlice.reducer;
