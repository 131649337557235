/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaCharacter({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3
       + checked4 + checked5 + checked6 + checked7
        + checked8 + checked9 + checked10 + checked11);
  });

  return (
    <>
      <div className="block-name">ГАМК - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я рано ложусь спать и рано встаю" checked={checked} setChecked={setChecked} />
        <Block text="Я пунктуален" checked={checked2} setChecked={setChecked2} />
        <Block text="Я стараюсь угодить другим, насколько могу" checked={checked3} setChecked={setChecked3} />
        <Block text="Я перфекционист" checked={checked4} setChecked={setChecked4} />
        <Block text="Я умею поддерживать длительные отношения" checked={checked5} setChecked={setChecked5} />
        <Block text="Я знаю, куда уходят мои деньги" checked={checked6} setChecked={setChecked6} />
        <Block text="Я верю, что чем больше моральных качеств, тем больше мира" checked={checked7} setChecked={setChecked7} />
        <Block text="Я очень верен и предан своим близким" checked={checked8} setChecked={setChecked8} />
        <Block text="У меня высокие этические стандарты, которыми я живу" checked={checked9} setChecked={setChecked9} />
        <Block text="Я уделяю большое внимание законам, принципам и политике" checked={checked10} setChecked={setChecked10} />
        <Block text="Я верю в социум и общество" checked={checked11} setChecked={setChecked11} />
      </div>
    </>
  );
}
export default GabaCharacter;
