/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useState, useEffect, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
import BannerFirstResult from '../../../components/banner/BannerFirstResult';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function ResultSerotonine() {
  showRedirectModal();
  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [dopaminecount * 2, acetylcholinecount * 2, gabacount * 2, serotoninecount * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  useEffect(() => {
    sessionStorage.setItem('first-result', JSON.stringify('/serotonine'));
  });
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Первая часть теста пройдена
            <div className="result-endtest-textsmall"> Ознакомьтесь с результатами и переходите ко второй части, когда будете готовы</div>
          </div>
          <div className="figure-div">
            <figure className="figure-blank">
              <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
              <figcaption>
                У вас серотониновая природа
              </figcaption>
            </figure>
          </div>
          <div className="pie">
            <Radar data={data} />
          </div>
          <aside>
            Если у вас серотониновая природа, вы относитесь к 17% населения, которые действительно умеют получать удовольствие от жизни.
          </aside>
          <div className="div-text">
            Серотонин связан с дельта-волнами ( которые в изобилии вырабатываются, когда мы спим ) и
            влияет на нашу способность отдыхать, восстанавливаться и чувстовать себя в безопасности.
            <br />
            <br />
            Вы реалист, остро реагируете на сенсорную информацию, но также можете быть импульсивным. Вы любите участвовать в событиях из-за «азарта»,
            а не воспринимая их, как средство для достижения цели.
            Достижение для вас означает добиться чего либо в моменте. Вам нравятся перемены — вы будете чередовать задачи и находить
            новые способы для их решения. Будете пробовать новое, находить хобби и каждый год планировать интересный отпуск.
            <br />
            <br />
            В уравновешенном состоянии человек с серотониновой природой восприимчив к раздражителям, находится в равновесии как с разумом, так и телом,
            часто физически скоординирован и очень находчив. Вас не останавливает борьба и не смущают неудачи.
            <p className="p-result">
              Если где-то есть адреналин, вы найдете его, будь то вечеринки, бокс,
              видеоигры, казино, альпинизм, прыжки с парашютом, катание на лыжах или охота на крокодилов.
            </p>
            <br />
            {/* <BannerFirstResult /> */}
            Вы пренебрегаете порядком и рутиной, а при вашей любви к независимости это может поставить в тупик ваши самые близкие отношения.
          </div>
          <Typography variant="h6" gutterBottom>
            Избыток серотонина
          </Typography>
          <aside>
            Выработка слишком большого количества серотонина может привести к появлению раздражительности.
            Вы можете стать нерешительным, рассеянным, уязвимым для любой критики и болезненно воспринимать неприязнь.
          </aside>
          <div className="div-text">
            В особых случаях человек с избыточным уровнем серотонина закрывается от людей, считает себя неполноценным.
            <br />
            <br />
            Таких людей мучают грусть, гнев и отчаянное желание
            общения, на которое, по иронии судьбы, они из за избытка серотонина в данный момент не способны.
          </div>
        </div>
      </div>
      <div className="result-nexttest-text">
        <Link
          to="/parttwo"
        >
          Вторая часть теста
        </Link>
      </div>
    </main>
  );
}
export default ResultSerotonine;
