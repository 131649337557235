/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useState, useEffect, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
import BannerFirstResult from '../../../components/banner/BannerFirstResult';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function ResultGaba() {
  showRedirectModal();
  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [dopaminecount * 2, acetylcholinecount * 2, gabacount * 2, serotoninecount * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  useEffect(() => {
    sessionStorage.setItem('first-result', JSON.stringify('/gaba'));
  });
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Первая часть теста пройдена
            <div className="result-endtest-textsmall"> Ознакомьтесь с результатами и переходите ко второй части, когда будете готовы</div>
          </div>
          <div className="figure-div">
            <figure className="figure-blank">
              <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
              <figcaption>
                У вас ГАМК природа
              </figcaption>
            </figure>
          </div>
          <div className="pie">
            <Radar data={data} />
          </div>
          <aside>
            Человек с природой ГАМК очень стабилен. В мире почти 50% людей с доминирующей ГАМК, отличительными чертами которой являются постоянство,
            общительность и забота о других.
          </aside>
          <div className="div-text">
            Когда ваша ГАМК находится в равновесии, вы сохраняете спокойствие, даже когда вокруг вас хаос.
            <br />
            <br />
            Такие качества, как объективность, уравновешенность, пунктуальность, практичность и уверенность, — все это естественно для вас.
            Вам очень важно оставаться организованным, жесткие графики для вас удобны,они не ограничивают — они устраняют неопределенность и обеспечивают результат.
            <br />
            <br />
            Независимо от того, чем вы занимаетесь, человек с ГАМК-натурой — это игрок в команде, кто остается сосредоточенным на решении вопросов,
            но обычно подчиняется большинству. Вам нравиться быть частью общества, получать удовольствие от выполнения своих обязательств и от заботы о тех, кого вы любите.
            <br />
            <br />
            Создание комфорта для других делает вас счастливым. Брак рассматривается как долгосрочная гавань.
            Вы любите книги по истории, биографии удивительных и умных людей
            <p className="p-result"> Вы рассудительны, уравновешены, не склонны к резким перепадам эмоций или вспышкам гнева. </p>
            <br />
            {/* <BannerFirstResult /> */}
            Бывают моменты, когда вы чувствуете, что оказали сильное влияние на других, например, после рабочего совещания или после спорта.
          </div>
          <Typography variant="h6" gutterBottom>
            Избыток ГАМК
          </Typography>
          <aside>
            Слишком большое количество ГАМК может усилить ваши склонности к заботе до такой степени, что вы будете игнорировать
            свои собственные потребности или в конечном итоге пострадаете.
          </aside>
          <div className="div-text">
            Вы можете тратить слишком много энергии на поиски любви, а затем слишком полагаться на своих партнеров.
            <br />
            <br />
            Вы можете слишком часто обращаться к авторитетным для вас лицам за советом, постоянно идти на компромис.
          </div>
        </div>
      </div>
      <div className="result-nexttest-text">
        <Link
          to="/parttwo"
        >
          Вторая часть теста
        </Link>
      </div>
    </main>
  );
}
export default ResultGaba;
