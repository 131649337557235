/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholineMemory({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6);
  });
  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="Мне не хватает воображения" checked={checked} setChecked={setChecked} />
        <Block text="Мне трудно запоминать имена" checked={checked2} setChecked={setChecked2} />
        <Block text="Я заметил, что моя память стала хуже" checked={checked3} setChecked={setChecked3} />
        <Block text="Моя вторая половинка говорит мне, что я не романтик" checked={checked4} setChecked={setChecked4} />
        <Block text="Я не могу запоминать дни рождения близких" checked={checked5} setChecked={setChecked5} />
        <Block text="Я стал менее творческим" checked={checked6} setChecked={setChecked6} />
      </div>
    </>
  );
}
export default AcetylcholineMemory;
