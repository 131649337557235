/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaMemory({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7);
  });

  return (
    <>
      <div className="block-name">ГАМК - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="Мне трудно сосредоточиться, потому что я часто нервничаю" checked={checked} setChecked={setChecked} />
        <Block text="Я не могу запомнить номера телефонов или похожую информацию" checked={checked2} setChecked={setChecked2} />
        <Block text="У меня проблемы с поиском подходящего слова" checked={checked3} setChecked={setChecked3} />
        <Block text="Мне трудно что-то вспомнить, когда меня ставят в затруднительное положение" checked={checked4} setChecked={setChecked4} />
        <Block text="Я знаю, что умный, но не могу это преподнести людям" checked={checked5} setChecked={setChecked5} />
        <Block text="Когда я читаю, то часто возвращаюсь к одному и тому же абзацу несколько раз, чтобы усвоить информацию." checked={checked6} setChecked={setChecked6} />
        <Block text="У меня проблемы с фокусировкой внимания" checked={checked7} setChecked={setChecked7} />
      </div>

    </>
  );
}
export default GabaMemory;
