import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from '../pages/Main/Main';
import Partonecontent from '../pages/Test/PartOne/Partonecontent';
import Partonecontent2 from '../pages/Test/PartOne/Partonecontent2';
import Partonecontent3 from '../pages/Test/PartOne/Partonecontent3';
import Partonecontent4 from '../pages/Test/PartOne/Partonecontent4';
import ResultDopamine from '../pages/Test/Result/ResultDopamine';
import ResultAcetylcholine from '../pages/Test/Result/ResultAcetilholine';
import ResultGaba from '../pages/Test/Result/ResultGaba';
import ResultSerotonine from '../pages/Test/Result/ResultSerotonine';
import Aboutneuro from '../pages/Neuro/Aboutneuro';
import Parttwocontent from '../pages/Test/PartTwo/Parttwocontent';
import Parttwocontent2 from '../pages/Test/PartTwo/Parttwocontent2';
import Parttwocontent3 from '../pages/Test/PartTwo/Parttwocontent3';
import Parttwocontent4 from '../pages/Test/PartTwo/Parttwocontent4';
import Notfound from '../pages/404/Notfound';
import ResultDopaminetwo from '../pages/Test/Result/ResultDopaminetwo';
import ResultGabatwo from '../pages/Test/Result/ResultGabatwo';
import ResultAcetylcholinetwo from '../pages/Test/Result/ResultAcetilholinetwo';
import ResultSerotoninetwo from '../pages/Test/Result/ResultSerotoninetwo';
import Agreemend from '../pages/Termsofuse/Agreemend';
import Emailform from '../pages/Email/emailmain';
import Licenses from '../pages/License/Licenses';

function WebRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route exact path="/partone" element={<Partonecontent />} />
      <Route exact path="/parttwo" element={<Parttwocontent />} />
      <Route exact path="/partone2" element={<Partonecontent2 />} />
      <Route exact path="/parttwo2" element={<Parttwocontent2 />} />
      <Route exact path="/partone3" element={<Partonecontent3 />} />
      <Route exact path="/parttwo3" element={<Parttwocontent3 />} />
      <Route exact path="/partone4" element={<Partonecontent4 />} />
      <Route exact path="/parttwo4" element={<Parttwocontent4 />} />
      <Route exact path="/resultdopamine" element={<ResultDopamine />} />
      <Route exact path="/resultdopaminetwo" element={<ResultDopaminetwo />} />
      <Route exact path="/resultacetylcholine" element={<ResultAcetylcholine />} />
      <Route exact path="/resultacetylcholinetwo" element={<ResultAcetylcholinetwo />} />
      <Route exact path="/resultgaba" element={<ResultGaba />} />
      <Route exact path="/resultgabatwo" element={<ResultGabatwo />} />
      <Route exact path="/resultserotonine" element={<ResultSerotonine />} />
      <Route exact path="/resultserotoninetwo" element={<ResultSerotoninetwo />} />
      <Route exact path="/aboutneuro" element={<Aboutneuro />} />
      <Route exact path="/useragreemend" element={<Agreemend />} />
      <Route exact path="/contacts" element={<Emailform />} />
      <Route exact path="/licenses" element={<Licenses />} />
      <Route exact path="*" element={<Notfound />} />

    </Routes>
  );
}

export default WebRoutes;
