/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotoninePersonality({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="У меня хроническая тревожность" checked={checked} setChecked={setChecked} />
        <Block text="Я легко раздражаюсь" checked={checked2} setChecked={setChecked2} />
        <Block text="У меня есть мысли о самоубийстве" checked={checked3} setChecked={setChecked3} />
        <Block text="Я склонен зацикливаться на своих идеях" checked={checked4} setChecked={setChecked4} />
        <Block text="Я более рационален и менее гибкий в мышлении" checked={checked5} setChecked={setChecked5} />
        <Block text="Мое воображение берет верх над реальностью" checked={checked6} setChecked={setChecked6} />
        <Block text="Меня ингда охватывает сильный страх" checked={checked7} setChecked={setChecked7} />
      </div>

    </>
  );
}
export default SerotoninePersonality;
