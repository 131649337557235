/* eslint-disable no-unused-vars */
import { React, useState } from 'react';
import { send } from 'emailjs-com';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HiOutlineMail } from 'react-icons/hi';
import './emailmain.css';
import { Helmet } from 'react-helmet';

function Emailform() {
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    message: '',
    reply_to: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_8rgpzhf',
      'template_b3vog29',
      toSend,
      'f4e9g-Itc4N9hsSZD',
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <main className="main-des">
      <Helmet>
        <title>Форма обратной связи</title>
        <meta
          name="description"
          content="Для связи с нами заполните форму на сайте"
        />
      </Helmet>
      <div className="email-form">
        <h3>Напишите нам</h3>
        <div className="email-form-text">По вопросам контента, сотрудничества, найденных ошибок и любым другим.</div>
        <form onSubmit={onSubmit}>
          <TextField
            className="mui-textfield"
            id="outlined-required"
            label="Имя - Обязательное поле"
            type="text"
            name="from_name"
            placeholder="Ваше имя"
            value={toSend.from_name}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            className="mui-textfield"
            multiline
            rows={4}
            id="outlined-required"
            label="Ваш текст - Обязательное поле"
            type="text"
            name="message"
            placeholder="Текст сообщения"
            value={toSend.message}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            className="mui-textfield"
            id="outlined-required"
            label="Email - Обязательное поле"
            type="email"
            name="reply_to"
            placeholder="Ваша почта"
            value={toSend.reply_to}
            onChange={handleChange}
            required
            fullWidth
          />
          <Button type="submit" className="mui-send-button" variant="contained" endIcon={<HiOutlineMail className="mui-send-icon" />}>
            Отправить
          </Button>
        </form>
      </div>
    </main>
  );
}
export default Emailform;
