/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useRef } from 'react';
import SerotonineTable from './Content/SerotonineTable';
import ResultChecker from './ResultChecker';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
// import BannerResult from '../../../components/banner/BannerResult';
import ShareBlock from '../../../components/shareblock/shareBlock';

function ResultSerotoninetwo() {
  showRedirectModal();
  const ref = useRef(null);
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Тест пройден
            <div className="result-endtest-textsmall">
              Теперь вы знаете ваш доминантный тип и дефицит нейромедиаторов.
              Ознакомьтесь с таблицей и сохраните себе результат
            </div>
          </div>
        </div>
        <ResultChecker />
        <div className="main-partone" ref={ref}>
          <div className="div-text-attention">По результатам теста у вас дефицит серотонина</div>
          <div className="div-text">
            Серотонин вырабатывается в больших количествах в затылочных долях и создает нейронные импульсы для органов восприятия,
            а также контролирует ваш голод.
            <br />
            <br />
            Если эти нейронные импульсы не синхронизированы, левая и правая половины вашего мозга будут выведены из равновесия, и вы можете почувствовать,
            переутомление, потерю самоконтроля и не сможете спокойно выспаться.
            <br />
            <br />
            Когда уровень серотонина несбалансирован, способность вашего мозга к самозарядке нарушается.
            Уровень серотонина может падать из-за чрезмерного возбуждения или недосыпания.
            <br />
            <br />
            Мало серотонина и вы просто не сможете ясно мыслить.
          </div>
          <div className="div-text-table">Для вас подготовлена таблица аминокислот и витаминов в соответствии с дефицитом нейромедиаторов.  </div>
          {/* <BannerResult /> */}
          <div className="div-text"><SerotonineTable /></div>
          <div className="div-text">
            Ваша память может стать неравномерной, и вы можете не реагировать на сенсорные стимулы так же быстро, как и раньше. Это вызывает забывчивость.
            <br />
          </div>
        </div>
        <div>
          {/* <Link type="button" to="/pdf/Serotonine.pdf" target="_blank" download className="div-text-save">СОХРАНИТЬ РЕЗУЛЬТАТ ТЕСТА</Link> */}
        </div>
        <ShareBlock urlProps="https://bnlab.ru/resultserotoninetwo" pdfSource="/pdf/Serotonine.pdf" />
      </div>
    </main>
  );
}
export default ResultSerotoninetwo;
