/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholinePersonality({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [checked17, setChecked17] = useState(false);
  const [checked18, setChecked18] = useState(false);
  const [checked19, setChecked19] = useState(false);
  const [checked20, setChecked20] = useState(false);
  const [checked21, setChecked21] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7 + checked8 + checked9 + checked10 + checked11
   + checked12 + checked13 + checked14 + checked15 + checked16 + checked17
    + checked18 + checked19 + checked20 + checked21);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="Я склонен к романтике" checked={checked} setChecked={setChecked} />
        <Block text="Я часто отдаю волю чувствам" checked={checked2} setChecked={setChecked2} />
        <Block text="Я склонен принимать решения, основываясь на догадках" checked={checked3} setChecked={setChecked3} />
        <Block text="Мне нравится размышлять" checked={checked4} setChecked={setChecked4} />
        <Block text="Иногда люди говорят, что я 'летаю в облаках'" checked={checked5} setChecked={setChecked5} />
        <Block text="Я люблю читать художественную литературу" checked={checked6} setChecked={setChecked6} />
        <Block text="У меня богатая фантазия" checked={checked7} setChecked={setChecked7} />
        <Block text="Я творчески подхожу к решению проблем людей" checked={checked8} setChecked={setChecked8} />
        <Block text="Я люблю говорить о том, что меня беспокоит" checked={checked9} setChecked={setChecked9} />
        <Block text="Обычно я бодр" checked={checked10} setChecked={setChecked10} />
        <Block text="Я верю в мистику" checked={checked11} setChecked={setChecked11} />
        <Block text="Я верю в родственные души" checked={checked12} setChecked={setChecked12} />
        <Block text="Меня интересует все загадочное" checked={checked13} setChecked={setChecked13} />
        <Block text="Я склонен чрезмерно реагировать на изменения в своем теле" checked={checked14} setChecked={setChecked14} />
        <Block text="Мне легко что-то менять" checked={checked15} setChecked={setChecked15} />
        <Block text="Я достаточно эмоционален" checked={checked16} setChecked={setChecked16} />
        <Block text="Я могу любить кого-то в одну минуту и ​​ненавидеть его или ее в следующую" checked={checked17} setChecked={setChecked17} />
        <Block text="Я стараюсь нравиться лицам другого пола" checked={checked18} setChecked={setChecked18} />
        <Block text="Я готов тратить деньги, если это идет на пользу отношений" checked={checked19} setChecked={setChecked19} />
        <Block text="Я склонен фантазировать, когда занимаюсь сексом" checked={checked20} setChecked={setChecked20} />
        <Block text="Мои отношения, как правило, наполнены романтикой." checked={checked21} setChecked={setChecked21} />
      </div>

    </>
  );
}
export default AcetylcholinePersonality;
