/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { configureStore } from '@reduxjs/toolkit';
import dopamineReducer from './slices/dopamineSlice';
import acetylcholineReducer from './slices/acetylcholineSlice';
import userReducer from './reducers/username';
import gabaReducer from './slices/gabaSlice';
import serotonineReducer from './slices/serotonineSlice';

const store = configureStore({
  reducer: {
    dopaminecounter: dopamineReducer,
    acetylcholinecounter: acetylcholineReducer,
    rootcounter: userReducer,
    gabacounter: gabaReducer,
    serotoninecounter: serotonineReducer,
  },
});

export default store;
