/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholinePhysical({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7 + checked8 + checked9);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="У меня обычно замедленный пульс" checked={checked} setChecked={setChecked} />
        <Block text="Мое тело в тонусе" checked={checked2} setChecked={setChecked2} />
        <Block text="У меня отличная фигура/сложение" checked={checked3} setChecked={setChecked3} />
        <Block text="У меня действительно низкий уровень холестерина" checked={checked4} setChecked={setChecked4} />
        <Block text="Когда я ем, то люблю ощущать ароматы и красоту еды." checked={checked5} setChecked={setChecked5} />
        <Block text="Я люблю йогу и растяжку мышц" checked={checked6} setChecked={setChecked6} />
        <Block text="Во время секса я очень чувственен" checked={checked7} setChecked={setChecked7} />
        <Block text="У меня часто проблемы с желудком" checked={checked8} setChecked={setChecked8} />
        <Block text="Мне нравится нетрадиционная медицина" checked={checked9} setChecked={setChecked9} />
      </div>
    </>
  );
}
export default AcetylcholinePhysical;
