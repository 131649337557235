/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import AntSwitch from './Switch';

function Block({ text, checked, setChecked }) {
  const vibrate = () => {
    if (!window) {
      return;
    }
    if (!window.navigator) {
      return;
    }
    if (!window.navigator.vibrate) {
      return;
    }
    window.navigator.vibrate(20);
  };
  return (
    <div id="question-partone" className="question-partone">
      <div id="question" className="question">{text}</div>
      <div id="answer" className="answer">
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              checked={checked}
              onChange={(event) => setChecked(event.target.checked) + vibrate()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </FormGroup>
      </div>
    </div>
  );
}
export default Block;
