/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaMemory({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7);
  });

  return (
    <>
      <div className="block-name">ГАМК - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="У меня отличная концентрация, и я могу легко искать логику других людей" checked={checked} setChecked={setChecked} />
        <Block text="Истории людей лучше, чем книги" checked={checked2} setChecked={setChecked2} />
        <Block text="Я запоминаю большую часть того, что слышу" checked={checked3} setChecked={setChecked3} />
        <Block text="Я могу помнить факты, которые мне рассказали" checked={checked4} setChecked={setChecked4} />
        <Block text="Я учусь на своем опыте" checked={checked5} setChecked={setChecked5} />
        <Block text="Я хорошо запоминаю имена" checked={checked6} setChecked={setChecked6} />
        <Block text="Я хороший слушатель" checked={checked7} setChecked={setChecked7} />
      </div>

    </>
  );
}
export default GabaMemory;
