/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholineCharacter({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7 + checked8);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я оптимист" checked={checked} setChecked={setChecked} />
        <Block text="Мне нравится помогать другим людям" checked={checked2} setChecked={setChecked2} />
        <Block text="Я верю, что все возможно, если предан своей цели" checked={checked3} setChecked={setChecked3} />
        <Block text="Благотворительность и альтруизм - это то, что всегда со мной" checked={checked4} setChecked={setChecked4} />
        <Block text="Другие думают, что я могу анализировать и видеть будущее" checked={checked5} setChecked={setChecked5} />
        <Block text="Мои мысли о религии часто меняются" checked={checked6} setChecked={setChecked6} />
        <Block text="Я идеалист, но не перфекционист" checked={checked7} setChecked={setChecked7} />
        <Block text="Я счастлив с тем, кто относится ко мне правильно" checked={checked8} setChecked={setChecked8} />
      </div>
    </>
  );
}
export default AcetylcholineCharacter;
