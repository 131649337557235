/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './Description.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Scroller from '../../components/scroller/scroll';
import Testbutton from '../../components/testbutton/button';
import ModalMobileBanner from '../../components/modalbanner/ModalMobileBanner';
import ModalDesktopBanner from '../../components/modalbanner/ModalDesktopBanner';
// const ModalBanner = lazy(() => import('./ModalBanner'));

const text = 'Ваш мозг уникален. Если ваше тело не функционирует должным образом, первое, на что необходимо обратить внимание - это мозг. Ваш мозг особенный и представляет собой уникальную биохимическую систему с набором нейромедиаторов.';
const text2 = 'Нейромедиаторы -  это те самые источники, дающие нам прекрасную палитру красок жизни.';
const text21 = ' Могут появляться такие признаки, как упадок сил, апатия, депрессия,раздражительность';
const text3 = 'Тест Брейвермана — это личностный тест, который классифицирует работу вашего мозга с помощью серии поведенческих вопросов, что дает более точный результат и показывает не только ваши сильные стороны, но и недостатки. Исправление недостатков может существенным образом изменить вашу жизнь к лучшему.';

function Description() {
  const [open, setOpen] = useState(false);
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  setTimeout(() => {
    if (!sessionStorage.getItem('banner')) { sessionStorage.setItem('banner', JSON.stringify(true)); }
  }, 1500);
  setTimeout(() => {
    setOpen(true);
  }, 4000);

  return (
    <main className="main-des">
      { (isMobile) ? <ModalMobileBanner open={open} setOpen={setOpen} /> : <ModalDesktopBanner open={open} setOpen={setOpen} />}
      <section className="section-des section-des-1">
        <div className="section-center-block">
          <div className="section-des-image-2">
            <img src="img/753.svg" alt="brain" />
          </div>
          <div className="section-des-text">
            {text}
          </div>
        </div>
        <Scroller />
      </section>
      <section className="section-des section-des-2">
        <div className="section-center-block">
          <div className="section-des-image-2">
            <img src="img/364.svg" alt="neuro" />
          </div>
          <div className="section-des-text-2">
            <div className="section-des-text-2">
              {text2}
              {' '}
              Дисбаланc
              {' '}
              <Link
                className="des-text-article-link"
                to="/aboutneuro"
              >
                нейромедиаторов
              </Link>
              {' '}
              может проявляться в изменении вашего эмоционального состояния.
              {text21}
            </div>
          </div>
        </div>
        <Scroller id="section-des-scroller-mobile" />
      </section>
      <section id="element" className="section-des section-des-3">
        <div className="section-center-block">
          <div className="section-des-image-3">
            <img src="img/757.svg" alt="brain" />
          </div>
          <div className="section-des-text-3">
            {text3}
          </div>
        </div>
        <Scroller />
      </section>
      <section className="section-des section-des-4">
        <div className="section-begin-test">
          <div className="section-begin-test-image">
            <img src="img/505.svg" alt="neuro" />
          </div>
          <div className="section-begin-test-text">
            <Testbutton />
          </div>
        </div>
      </section>
    </main>
  );
}
export default Description;
