/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholineMemory({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7 + checked8 + checked9);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="У меня хорошая память" checked={checked} setChecked={setChecked} />
        <Block text="Я отличный слушатель" checked={checked2} setChecked={setChecked2} />
        <Block text="Я хорошо запоминаю истории" checked={checked3} setChecked={setChecked3} />
        <Block text="Я обычно не забываю лица" checked={checked4} setChecked={setChecked4} />
        <Block text="Я очень креативный" checked={checked5} setChecked={setChecked5} />
        <Block text="У меня отличная концентрация и я редко что-то пропускаю" checked={checked6} setChecked={setChecked6} />
        <Block text="Я в основном вижу положительные моменты" checked={checked7} setChecked={setChecked7} />
        <Block text="Я замечаю все, что происходит вокруг меня" checked={checked8} setChecked={setChecked8} />
        <Block text="У меня хорошее воображение" checked={checked9} setChecked={setChecked9} />
      </div>
    </>
  );
}
export default AcetylcholineMemory;
