/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useRef } from 'react';
import Gabatable from './Content/Gabatable';
import ResultChecker from './ResultChecker';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
// eslint-disable-next-line no-unused-vars
// import BannerResult from '../../../components/banner/BannerResult';
import ShareBlock from '../../../components/shareblock/shareBlock';

function ResultGabatwo() {
  showRedirectModal();
  const ref = useRef(null);
  return (
    <main className="main-des" id="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Тест пройден
            <div className="result-endtest-textsmall">
              Теперь вы знаете ваш доминантный тип и дефицит нейромедиаторов.
              Ознакомьтесь с таблицей и сохраните себе результат
            </div>
          </div>
        </div>
        <ResultChecker />
        <div className="main-partone" ref={ref}>
          <div className="div-text-attention">По результатам теста у вас дефицит ГАМК</div>
          <div className="div-text">
            Когда ваш ритм сбивается из-за дефицита ГАМК, вы можете начать чувствовать беспокойство, нервозность или раздражительность.
          </div>
          <div className="div-text-table">
            Для вас подготовлена таблица аминокислот и витаминов в соответствии с дефицитом нейромедиаторов.
            {/* <BannerResult /> */}
          </div>
          <div className="div-text"><Gabatable /></div>
          <div className="div-text">
            ГАМК является основным тормозным нейротрансмиттером мозга, который контролирует все остальные биохимические вещества. ГАМК контролирует ритм мозга,
            так что вы чувствуете себя стабильно физически и эмоционально.
            <br />
            <br />
            Без достаточного количества ГАМК ваш мозг вырабатывает энергию всплесками, что влияет на ваше эмоциональное благополучие.
            <br />
            <br />
            Возможны проблемы с вниманием: трудности с концентрацией, неорганизованный характер внимания,
            связанный с тревогой и неспособность ясно мыслить.
            <br />
          </div>
        </div>
        <div>
          {/* <Link type="button" to="/pdf/Gaba.pdf" target="_blank" download className="div-text-save">СОХРАНИТЬ РЕЗУЛЬТАТ ТЕСТА</Link> */}
        </div>
        <ShareBlock urlProps="https://bnlab.ru/resultgabatwo" pdfSource="/pdf/Gaba.pdf" />
      </div>
    </main>
  );
}
export default ResultGabatwo;
