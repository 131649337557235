/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../testcontent.css';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DopamineMemory from './Dopamine/DopamineMemory';
import DopaminePhysical from './Dopamine/DopaminePhysical';
import DopamineCharacter from './Dopamine/DopamineCharacter';
import DopaminePersonality from './Dopamine/DopaminePersonality';
import { dopamineslice } from '../../../store/slices/dopamineSlice';
import ElevateAppBar from './Sticky';
import Resultloader from '../../../components/loader/resultloader';
import TestStepper from '../UI/Teststepper';
import NextButtonBlock from '../UI/Testnextblock';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';

const steps = ['Дофамин - память и внимание', 'ДОФАМИН - Физиология', 'ДОФАМИН - Характер', 'ДОФАМИН - Личность'];

function Partonecontent() {
  showRedirectModal();
  const [dopamineresult, setDopamineresult] = useState(0);
  const [dopamineresult2, setDopamineresult2] = useState(0);
  const [dopamineresult3, setDopamineresult3] = useState(0);
  const [dopamineresult4, setDopamineresult4] = useState(0);
  const dopaminesum = dopamineresult + dopamineresult2 + dopamineresult3 + dopamineresult4;
  const dispatch = useDispatch();
  if (!localStorage.getItem('is-open')) { sessionStorage.setItem('is-open', JSON.stringify(true)); }
  useEffect(() => {
    dispatch(dopamineslice(dopaminesum));
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 10; // если нужно ставить OPTIONAL для возможности пропустить

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigate = useNavigate();

  if (activeStep === steps.length) {
    setTimeout(() => {
      navigate('/partone2');
    }, 4000);
  }
  return (
    <>
      <ElevateAppBar />
      <Box sx={{ width: '100%' }} className="partonebox">
        <TestStepper activeStep={activeStep} isStepOptional={isStepOptional} isStepSkipped={isStepSkipped} steps={steps} />
        { activeStep === steps.length && (
        <>
          {sessionStorage.setItem('is-open', JSON.stringify(false))}
          <div className="partonenext-content">
            <div className="partonenext-text">
              Данные по дофамину собраны и обрабатываются
            </div>
          </div>
          <div className="partone-loading">
            <Resultloader />
          </div>
        </>
        )}
        { activeStep === 0 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <DopamineMemory setDopamineresult={setDopamineresult2} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 1 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <DopaminePhysical setDopamineresult={setDopamineresult3} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 2 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <DopamineCharacter setDopamineresult={setDopamineresult} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1}///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 3 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <DopaminePersonality setDopamineresult={setDopamineresult4} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
      </Box>
    </>
  );
}

export default Partonecontent;
