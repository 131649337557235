/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaPhysical({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4
       + checked5 + checked6 + checked7 + checked8
       + checked9 + checked10 + checked11 + checked12 + checked13);
  });

  return (
    <>
      <div className="block-name">ГАМК - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="Мне легко расслабиться" checked={checked} setChecked={setChecked} />
        <Block text="Я спокойный человек" checked={checked2} setChecked={setChecked2} />
        <Block text="Мне легко заснуть ночью" checked={checked3} setChecked={setChecked3} />
        <Block text="у меня хорошая физическая выносливость" checked={checked4} setChecked={setChecked4} />
        <Block text="У меня обычно низкое давление" checked={checked5} setChecked={setChecked5} />
        <Block text="В моей семье небыло инсульта" checked={checked6} setChecked={setChecked6} />
        <Block text="Когда дело доходит до секса, я не люблю эксперименты." checked={checked7} setChecked={setChecked7} />
        <Block text="У меня небольшое мышечное напряжение" checked={checked8} setChecked={setChecked8} />
        <Block text="Кофеин мало влияет на меня" checked={checked9} setChecked={setChecked9} />
        <Block text="Я не тороплюсь за едой" checked={checked10} setChecked={setChecked10} />
        <Block text="Я хорошо высыпаюсь" checked={checked11} setChecked={setChecked11} />
        <Block text="У меня нет пристрастия к вредным продуктам, таким как сахар" checked={checked12} setChecked={setChecked12} />
        <Block text="Физические упражнения для меня – это ежедневная привычка" checked={checked13} setChecked={setChecked13} />
      </div>

    </>
  );
}
export default GabaPhysical;
