/* eslint-disable no-sequences */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function NextButtonBlock({
  steps, activeStep, handleBack, isStepOptional, handleSkip, handleNext,
}) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', pr: 1, pl: 1, pb: 1,
    }}
    >
      <Button
        color="info"
        variant="contained"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ backgroundColor: '#0072b1' }}
      >
        Назад
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      {isStepOptional(activeStep) && (
      <Button color="inherit" onClick={handleSkip} sx={{ mr: 0 }}>
        Пропустить
      </Button>
      )}
      <Button
        sx={{ backgroundColor: '#0072b1' }}
        color="info"
        variant="contained"
        onClick={() => { scrollToTop(); handleNext(); }}
      >
        {activeStep === steps.length - 1 ? 'Следующий этап' : 'Вперед'}
      </Button>
    </Box>
  );
}

export default NextButtonBlock;
