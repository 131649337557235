/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaPersonality({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [checked17, setChecked17] = useState(false);
  const [checked18, setChecked18] = useState(false);
  const [checked19, setChecked19] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7 + checked8 + checked9 + checked10 + checked11
   + checked12 + checked13 + checked14 + checked15 + checked16 + checked17
    + checked18 + checked19);
  });

  return (
    <>
      <div className="block-name">ГАМК - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="Я не авантюрист" checked={checked} setChecked={setChecked} />
        <Block text="Обычно я не темпераментный" checked={checked2} setChecked={setChecked2} />
        <Block text="У меня отличное терпение" checked={checked3} setChecked={setChecked3} />
        <Block text="Философия не для меня" checked={checked4} setChecked={setChecked4} />
        <Block text="Я люблю смотреть кино и сериалы про семейные отношения" checked={checked5} setChecked={setChecked5} />
        <Block text="Я не люблю фильмы о других мирах или вселенных" checked={checked6} setChecked={setChecked6} />
        <Block text="Я не любитель рисковать" checked={checked7} setChecked={setChecked7} />
        <Block text="Я опираюсь на свой прошлый опыт, прежде чем принимать решения" checked={checked8} setChecked={setChecked8} />
        <Block text="Я реалист" checked={checked9} setChecked={setChecked9} />
        <Block text="Я думаю, что всегда можно договориться" checked={checked10} setChecked={setChecked10} />
        <Block text="Я люблю факты и детали" checked={checked11} setChecked={setChecked11} />
        <Block text="Когда я принимаю решение, это навсегда" checked={checked12} setChecked={setChecked12} />
        <Block text="Мне нравится планировать свой день, неделю, месяц и т. д." checked={checked13} setChecked={setChecked13} />
        <Block text="Мне нравиться коллекционировать" checked={checked14} setChecked={setChecked14} />
        <Block text="Мне обычно немного грустно" checked={checked15} setChecked={setChecked15} />
        <Block text="Я боюсь конфликтов и ссор" checked={checked16} setChecked={setChecked16} />
        <Block text="Я коплю деньги на случай кризиса" checked={checked17} setChecked={setChecked17} />
        <Block text="Я склонен создавать прочные, длительные связи с другими людьми" checked={checked18} setChecked={setChecked18} />
        <Block text="Я стабильная опора в жизни людей" checked={checked19} setChecked={setChecked19} />
      </div>
    </>
  );
}
export default GabaPersonality;
