/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotonineMemory({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3 + checked4
  + checked5 + checked6 + checked7 + checked8);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="Я могу легко сосредоточиться на ручной работе" checked={checked} setChecked={setChecked} />
        <Block text="у меня хорошая зрительная память" checked={checked2} setChecked={setChecked2} />
        <Block text="я очень проницателен" checked={checked3} setChecked={setChecked3} />
        <Block text="Я импульсивный" checked={checked4} setChecked={setChecked4} />
        <Block text="Я живу одним днем" checked={checked5} setChecked={setChecked5} />
        <Block text="Я обычно пытаюсь разобраться в деталях" checked={checked6} setChecked={setChecked6} />
        <Block text="Я медленно учусь по книгам, но легко учусь на собственном опыте." checked={checked7} setChecked={setChecked7} />
        <Block text="Мне нужна практика, а не теория" checked={checked8} setChecked={setChecked8} />
      </div>

    </>
  );
}
export default SerotonineMemory;
