/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotonineCharacter({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я не могу перестать думать о смысле жизни" checked={checked} setChecked={setChecked} />
        <Block text="Я больше не рискую" checked={checked2} setChecked={setChecked2} />
        <Block text="Если в моей жизни нет смысла, то я чувствую разочарование" checked={checked3} setChecked={setChecked3} />
      </div>

    </>
  );
}
export default SerotonineCharacter;
