/* eslint-disable react/prop-types */
import {
  EmailIcon,
  EmailShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,

} from 'react-share';

import React from 'react';

import './shareBlock.css';
import { Link } from 'react-router-dom';

function ShareBlock({ urlProps, pdfSource }) {
  const shareUrl = urlProps;
  const title = 'Тест Бравермана - Результат';

  return (
    <div className="mainshareblock">
      <div className="shareblock">
        <div className="shareblocktext">Поделись или сохрани результат</div>
        <div className="mainshare_div_button">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="mainshare_div_button">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className="mainshare_div_button">
          <VKShareButton
            url={shareUrl}
                  //   image={`${String(window.location)}/src/${image}`}
            image="https://bnlab.ru/img/image.jpg"
            className="Demo__some-network__share-button"
          >
            <VKIcon size={32} round />
          </VKShareButton>
        </div>

        <div className="mainshare_div_button">
          <OKShareButton
            url={shareUrl}
            image="https://bnlab.ru/img/image.jpg"
                  //   image={`${String(window.location)}/src/${image}`}
            className="Demo__some-network__share-button"
          >
            <OKIcon size={32} round />
          </OKShareButton>
        </div>

        <div className="mainshare_div_button">
          <MailruShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <MailruIcon size={32} round />
          </MailruShareButton>
        </div>

        <div className="mainshare_div_button">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>
      <div className="pdfbuttonblock">
        <div className="mainshare_div_button">
          <Link type="button" to={pdfSource} target="_blank" download className="div-text-save-button">СКАЧАТЬ PDF</Link>
        </div>
      </div>
    </div>
  );
}

export default ShareBlock;
