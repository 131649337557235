/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholineCharacter({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Меня не интересуют чьи-либо проблемы, кроме моих собственных" checked={checked} setChecked={setChecked} />
        <Block text="Я не обращаю внимания на чувства других людей" checked={checked2} setChecked={setChecked2} />
        <Block text="Я перестал чувствовать себя бодрым" checked={checked3} setChecked={setChecked3} />
      </div>
    </>
  );
}
export default AcetylcholineCharacter;
