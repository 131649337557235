/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useState, useEffect, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
import BannerFirstResult from '../../../components/banner/BannerFirstResult';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function ResultDopamine() {
  showRedirectModal();
  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [dopaminecount * 2, acetylcholinecount * 2, gabacount * 2, serotoninecount * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  useEffect(() => {
    sessionStorage.setItem('first-result', JSON.stringify('/dopamine'));
  });
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Первая часть теста пройдена
            <div className="result-endtest-textsmall"> Ознакомьтесь с результатами и переходите ко второй части, когда будете готовы</div>
          </div>
          <div className="figure-div">
            <figure className="figure-blank">
              <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
              <figcaption>
                У вас дофаминовая природа
              </figcaption>
            </figure>
          </div>
          <div className="pie">
            <Radar data={data} />
          </div>
          <aside>
            Если у вас дофаминовая природа, вы относитесь к 17% населения.  Вы уверены в себе и крайне рациональны.
            {' '}
          </aside>
          <div className="div-text">
            Когда вы уравновешены, то вероятно, будете крайне волевым человеком,
            который точно знает, что хочет и как этого добиться.
            <br />
            <br />
            Вам удобнее работать с фактами и цифрами, чем с чувствами и эмоциями. Вы способны критически оценивать себя, но можете плохо реагировать на критику других.
            Вы сосредотачиваетесь на поставленной задаче и гордитесь своими достижениями.
            Стратегическое мышление, изобретательность, решение проблем, воображение и прагматизм — это для вас.Вы хорошо
            работаете в условиях стресса.
            <br />
            <br />
            Большинство врачей, ученых, исследователей, изобретателей, инженеров, военных и архитекторов являются дофаминовыми доминантами.
            В прочем, это не показатель для выбора профессии. Вам может нравиться играть в шахматы, слушать аудиокниги или разгадывать сложные кроссворды.
            Вы предпочитаете силовые тренировки больше , чем аэробные упражнения.
            <br />
            <br />
            <p className="p-result">Возможно, вы не слишком чувствительны и упускаете из виду моменты, когда другие считают свои чувства более важными, чем ваши доводы.</p>
            <br />
            {/* <BannerFirstResult /> */}
            Легкий эгоизм может повлиять на ваи отношения с близкими,
            и стабильность вашего брака может зависеть от верности и доброжелательности вашего супруга.
          </div>
          <Typography variant="h6" gutterBottom>
            Избыток дофамина
          </Typography>
          <aside>
            Cлишком много дофамина может привести к чрезмерно рискованному поведению и импульсивным действиям.
          </aside>
          <div className="div-text">
            Проявление насилия и чрезмерный контроль над другими могут стать вашими спутниками при избытке дофамина.
            <br />
          </div>
        </div>
      </div>
      <div className="result-nexttest-text">
        <Link
          to="/parttwo"
          className="result-nexttest-font"
        >
          Вторая часть теста
        </Link>
      </div>
    </main>
  );
}
export default ResultDopamine;
