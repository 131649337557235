/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../ResultDopamine.css';
import React, { useState, useEffect, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function GabaforResult() {
  // const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  // const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  // const gabacount = useSelector((state) => state.gabacounter.value);
  // const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const [ddata] = useState(Number(sessionStorage.getItem('dgraph')));
  const [adata] = useState(Number(sessionStorage.getItem('agraph')));
  const [gdata] = useState(Number(sessionStorage.getItem('ggraph')));
  const [sdata] = useState(Number(sessionStorage.getItem('sgraph')));

  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [ddata * 2, adata * 2, gdata * 2, sdata * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  return (
    <div id="partone" className="main-partone">
      <div className="result-main-text">
        <div className="figure-div">
          <figure className="figure-blank">
            <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
            <figcaption>
              У вас ГАМК природа
            </figcaption>
          </figure>
        </div>
        <div className="pie">
          <Radar data={data} />
        </div>
        <aside>
          Человек с природой ГАМК очень стабилен. В мире почти 50% людей с доминирующей ГАМК, отличительными чертами которой являются постоянство,
          общительность и забота о других.
        </aside>
        <div className="div-text">
          Когда ваша ГАМК находится в равновесии, вы сохраняете спокойствие, даже когда вокруг вас хаос.
          <br />
          <br />
          Такие качества, как объективность, уравновешенность, пунктуальность, практичность и уверенность, — все это естественно для вас.
          Вам очень важно оставаться организованным, жесткие графики для вас удобны,они не ограничивают — они устраняют неопределенность и обеспечивают результат.
          <br />
          <br />
          Независимо от того, чем вы занимаетесь, человек с ГАМК-натурой — это игрок в команде, кто остается сосредоточенным на решении вопросов,
          но обычно подчиняется большинству. Вам нравиться быть частью общества, получать удовольствие от выполнения своих обязательств и от заботы о тех, кого вы любите.
          <br />
          <br />
          Создание комфорта для других делает вас счастливым. Брак рассматривается как долгосрочная гавань.
          Вы любите книги по истории, биографии удивительных и умных людей
          <p className="p-result"> Вы рассудительны, уравновешены, не склонны к резким перепадам эмоций или вспышкам гнева. </p>
          Бывают моменты, когда вы чувствуете, что оказали сильное влияние на других, например, после рабочего совещания или после спорта.
        </div>
      </div>
    </div>
  );
}
export default GabaforResult;
