/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaCharacter({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3
       + checked4 + checked5 + checked6);
  });

  return (
    <>
      <div className="block-name">ГАМК - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я больше не играю по правилам" checked={checked} setChecked={setChecked} />
        <Block text="Я теряю связь с друзьями и близкими" checked={checked2} setChecked={setChecked2} />
        <Block text="Романтика - это не для меня" checked={checked3} setChecked={setChecked3} />
        <Block text="Я перфекционист" checked={checked4} setChecked={setChecked4} />
        <Block text="Законы для меня произвольны и необоснованны" checked={checked5} setChecked={setChecked5} />
        <Block text="Теперь я считаю странными правила, которым раньше следовал." checked={checked6} setChecked={setChecked6} />
      </div>
    </>
  );
}
export default GabaCharacter;
