/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaPhysical({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4
       + checked5 + checked6 + checked7 + checked8
        + checked9 + checked10 + checked11 + checked12 + checked13);
  });

  return (
    <>
      <div className="block-name">ГАМК - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="У меня дрожат руки" checked={checked} setChecked={setChecked} />
        <Block text="У меня частые боли в спине и/или головные боли" checked={checked2} setChecked={setChecked2} />
        <Block text="у меня часто бывает одышка" checked={checked3} setChecked={setChecked3} />
        <Block text="У меня часто бывает учащенное сердцебиение" checked={checked4} setChecked={setChecked4} />
        <Block text="Обычно у меня холодные руки" checked={checked5} setChecked={setChecked5} />
        <Block text="Иногда я слишком сильно потею" checked={checked6} setChecked={setChecked6} />
        <Block text="Иногда у меня кружится голова" checked={checked7} setChecked={setChecked7} />
        <Block text="у меня часто эффект 'бабочки в животе'" checked={checked8} setChecked={setChecked8} />
        <Block text="Я немогу без острой пищи" checked={checked9} setChecked={setChecked9} />
        <Block text="Я часто нервничаю" checked={checked10} setChecked={setChecked10} />
        <Block text="Мне нравится йога, потому что помогает мне расслабиться" checked={checked11} setChecked={setChecked11} />
        <Block text="Я часто чувствую себя усталым, даже когда хорошо выспался" checked={checked12} setChecked={setChecked12} />
        <Block text="Я чувствую, что перегорел" checked={checked13} setChecked={setChecked13} />
      </div>

    </>
  );
}
export default GabaPhysical;
