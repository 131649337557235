import './App.css';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Layout from './pages/Layout/Layout';
import CheckModal from './components/checkmodal/CheckModal';

function App() {
  return (
    <BrowserRouter>
      <CheckModal />
      <div>
        <Layout />
      </div>
    </BrowserRouter>
  );
}
export default App;
