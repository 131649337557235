/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  Dialog, DialogContent,
} from '@mui/material';
import React, { useState, useLayoutEffect } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './CheckModal.css';
import { Link } from 'react-router-dom';

export default function CheckModal() {
  const [open, setOpen] = useState(true);
  const [render, setRender] = useState(false);
  useLayoutEffect(() => {
    if (sessionStorage.getItem('start') === JSON.stringify(true)) {
      setRender(true);
    }
  });
  const handleToggleClose = () => {
    setOpen(false);
  };
  const handleToggleStorage = () => {
    sessionStorage.setItem('start', JSON.stringify(false));
    setOpen(false);
  };
  if (render) {
    return (
      <div>
        <Dialog
          className="checkmodal-main"
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IoIosCloseCircleOutline
            className="checkmodal-closebutton"
            type="button"
            onClick={handleToggleClose}
          />
          <DialogContent className="test">
            <div className="checkmodal-block">
              <div className="checkmodal-header">BNLAB.RU</div>
              <img
                className="checkmodal-image"
                src="img/ban7.jpg"
                alt="bnlab"
              />
              <div className="checkmodal-text">Вы на верном пути.Что бы начать тест переходите на главную страницу</div>
              <Link to="/">
                <button className="checkmodal-button" type="button" onClick={handleToggleStorage}>
                  НА ГЛАВНУЮ
                </button>
              </Link>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
