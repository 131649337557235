/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotoninePersonality({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [checked17, setChecked17] = useState(false);
  const [checked18, setChecked18] = useState(false);
  const [checked19, setChecked19] = useState(false);
  const [checked20, setChecked20] = useState(false);
  const [checked21, setChecked21] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7 + checked8 + checked9 + checked10 + checked11
   + checked12 + checked13 + checked14 + checked15 + checked16 + checked17
    + checked18 + checked19 + checked20 + checked21);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="Я живу здесь и сейчас" checked={checked} setChecked={setChecked} />
        <Block text="Я люблю выступать/развлекаться на публике" checked={checked2} setChecked={setChecked2} />
        <Block text="Я часто не могу анализировать факты" checked={checked3} setChecked={setChecked3} />
        <Block text="Я могу подстраиваться под людей и ситуацию" checked={checked4} setChecked={setChecked4} />
        <Block text="Я отличный собеседник" checked={checked5} setChecked={setChecked5} />
        <Block text="Я часто просто люблю «есть, пить и веселиться»." checked={checked6} setChecked={setChecked6} />
        <Block text="Я драматичен" checked={checked7} setChecked={setChecked7} />
        <Block text="Я очень артистичен" checked={checked8} setChecked={setChecked8} />
        <Block text="Я хороший специалист в части своей работы" checked={checked9} setChecked={setChecked9} />
        <Block text="Я рискую, когда дело доходит до спорта" checked={checked10} setChecked={setChecked10} />
        <Block text="Я верю в экстрасенсов" checked={checked11} setChecked={setChecked11} />
        <Block text="Я могу легко использовать других людей" checked={checked12} setChecked={setChecked12} />
        <Block text="Я циничен по отношению к чужому мнению" checked={checked13} setChecked={setChecked13} />
        <Block text="Я люблю веселиться" checked={checked14} setChecked={setChecked14} />
        <Block text="Мой любимый тип фильмов - фильмы ужасов" checked={checked15} setChecked={setChecked15} />
        <Block text="Мне нравиться оружие" checked={checked16} setChecked={setChecked16} />
        <Block text="Я редко придерживаюсь плана" checked={checked17} setChecked={setChecked17} />
        <Block text="Мне трудно оставаться верным" checked={checked18} setChecked={setChecked18} />
        <Block text="Я легко могу расстаться и жить дальше, когда отношения с близкими заканчиваются" checked={checked19} setChecked={setChecked19} />
        <Block text="Я не обращаю особого внимания на то, как я трачу свои деньги" checked={checked20} setChecked={setChecked20} />
        <Block text="У меня много легкомысленных отношений" checked={checked21} setChecked={setChecked21} />
      </div>

    </>
  );
}
export default SerotoninePersonality;
