/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const acetylcholineSlice = createSlice({
  name: 'acetylcholine',
  initialState,
  reducers: {
    acetylcholineslice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { acetylcholineslice } = acetylcholineSlice.actions;

export default acetylcholineSlice.reducer;
