/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import './Layout.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header';
import WebRoutes from '../../Routes/Webroutes';

function Layout() {
  return (
    <>
      <Helmet>
        <title>Тест Бравермана - Тест на нейромедиаторы</title>
      </Helmet>
      <div id="layout" className="main-layout">
        <Header />
      </div>
      <WebRoutes />
    </>
  );
}
export default Layout;
