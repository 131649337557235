/* eslint-disable max-len */
import React from 'react';
import './Agreemend.css';
import { Helmet } from 'react-helmet';

function Agreemend() {
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  return (
    <main className="main-des">
      <Helmet>
        <title>Пользовательское соглашение</title>
        <meta
          name="description"
          content="Пользовательское соглашение."
        />
      </Helmet>
      <div className="agreemend-main">
        <article className="agreemend-article">
          <h2>Пользовательское Соглашение</h2>
          <p>
            <br />
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем bnlab.ru
            (далее Bio Neuronica или Администрация) с одной стороны и пользователем сайта с другой.
            Сайт Bio Neuronica не является средством массовой информации.
            <br />
            <br />
            Используя сайт, Вы соглашаетесь с условиями данного соглашения.
            Если Вы не согласны с условиями данного соглашения, не используйте сайт Bio Neuronica!
            <br />
            <br />
            <h3>Права и обязанности сторон</h3>
            <br />
            Пользователь имеет право:
            <br />
            <ul>
              <li>- осуществлять поиск информации на сайте</li>
              <li>- получать информацию на сайте</li>
              <li>- копировать информацию на другие сайты с указанием источника</li>
              <li>- использовать информацию сайта в личных некоммерческих целях</li>
            </ul>
            <br />
            Администрация имеет право:
            <br />
            <ul>
              <li>- по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
              <li>- ограничивать доступ к любой информации на сайте</li>
              <li>- создавать, изменять, удалять информацию</li>
            </ul>
            <br />
            Пользователь обязуется:
            <br />
            <ul>
              <li>
                - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной,
                расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой
                предусмотрена уголовная или административная ответственность
              </li>
              <li>- не нарушать работоспособность сайта</li>
            </ul>
            <br />
            Администрация обязуется:
            <br />
            <ul>
              <li>
                - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим
                от Администрации причинам.
              </li>
            </ul>
            <br />
            <h3>Ответственность сторон</h3>
            <br />
            <ul>
              <li>- пользователь лично несет полную ответственность за распространяемую им информацию</li>
              <li>
                - администрация не несет никакой ответственности за достоверность информации, скопированной из других источников
              </li>
              <li>- администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг</li>
              <li>- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
              <li>
                - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.)
                Администрация не гарантирует сохранность информации, размещённой Пользователем,
                а также бесперебойную работу информационного ресурса
              </li>
            </ul>
            <br />
            <h3>Условия действия Соглашения</h3>
            <br />
            <ul>
              <li>Данное Соглашение вступает в силу при любом использовании данного сайта.</li>
              <li>Соглашение перестает действовать при появлении его новой версии.</li>
              <li>Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.</li>
              <li>Администрация не оповещает пользователей об изменении в Соглашении.</li>
            </ul>
          </p>
        </article>

      </div>
    </main>
  );
}
export default Agreemend;
