/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../ResultDopamine.css';
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function DopamineforResult() {
  const [ddata] = useState(Number(sessionStorage.getItem('dgraph')));
  const [adata] = useState(Number(sessionStorage.getItem('agraph')));
  const [gdata] = useState(Number(sessionStorage.getItem('ggraph')));
  const [sdata] = useState(Number(sessionStorage.getItem('sgraph')));

  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [ddata * 2, adata * 2, gdata * 2, sdata * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  return (
    <div id="partone" className="main-partone">
      <div className="result-main-text">
        <div className="figure-div">
          <figure className="figure-blank">
            <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
            <figcaption>
              У вас дофаминовая природа
            </figcaption>
          </figure>
        </div>
        <div className="pie">
          <Radar data={data} />
        </div>
        <aside>
          Если у вас дофаминовая природа, вы относитесь к 17% населения.  Вы уверены в себе и крайне рациональны.
          {' '}
        </aside>
        <div className="div-text">
          Когда вы уравновешены, то вероятно, будете крайне волевым человеком,
          который точно знает, что хочет и как этого добиться.
          <br />
          <br />
          Вам удобнее работать с фактами и цифрами, чем с чувствами и эмоциями. Вы способны критически оценивать себя, но можете плохо реагировать на критику других.
          Вы сосредотачиваетесь на поставленной задаче и гордитесь своими достижениями.
          Стратегическое мышление, изобретательность, решение проблем, воображение и прагматизм — это для вас.Вы хорошо
          работаете в условиях стресса.
          <br />
          <br />
          Большинство врачей, ученых, исследователей, изобретателей, инженеров, военных и архитекторов являются дофаминовыми доминантами.
          В прочем, это не показатель для выбора профессии. Вам может нравиться играть в шахматы, слушать аудиокниги или разгадывать сложные кроссворды.
          Вы предпочитаете силовые тренировки больше , чем аэробные упражнения.
          <p className="p-result">Возможно, вы не слишком чувствительны и упускаете из виду моменты, когда другие считают свои чувства более важными, чем ваши доводы.</p>
          Легкий эгоизм может повлиять на ваи отношения с близкими,
          и стабильность вашего брака может зависеть от верности и доброжелательности вашего супруга.
        </div>
        <aside>
          Cлишком много дофамина может привести к чрезмерно рискованному поведению и импульсивным действиям.
        </aside>
      </div>
    </div>
  );
}
export default DopamineforResult;
