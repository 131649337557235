import React, { useState } from 'react';
import SerotonineforResult from './Content/SerotonineforResult';
import DopamineforResult from './Content/DopamineforResult';
import GabaforResult from './Content/GabaforResult';
import AcetilholineforResult from './Content/AcetilholineforResult';

function ResultChecker() {
  const [checksessionstorage] = useState(sessionStorage.getItem('first-result'));
  return (
    <>
      { checksessionstorage === '"/serotonine"' && (
        <SerotonineforResult />
      )}
      { checksessionstorage === '"/dopamine"' && (
        <DopamineforResult />
      )}
      { checksessionstorage === '"/gaba"' && (
        <GabaforResult />
      )}
      { checksessionstorage === '"/acetilholine"' && (
        <AcetilholineforResult />
      )}
    </>
  );
}

export default ResultChecker;
