/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopamineMemory({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);

  useEffect(() => {
    setDopamineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6 + checked7 + checked8);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="Мне легко решать задачи в уме" checked={checked} setChecked={setChecked} />
        <Block text="Мне легко сконцентрироваться" checked={checked2} setChecked={setChecked2} />
        <Block text="Я могу мыслить глубоко" checked={checked3} setChecked={setChecked3} />
        <Block text="Я быстро соображаю" checked={checked4} setChecked={setChecked4} />
        <Block text="Я отвлекаюсь, когда делаю много дел одновременно" checked={checked5} setChecked={setChecked5} />
        <Block text="Мне нравится спорить" checked={checked6} setChecked={setChecked6} />
        <Block text="У меня хорошее воображение" checked={checked7} setChecked={setChecked7} />
        <Block text="Я склонен к самокритике и самоанализу" checked={checked8} setChecked={setChecked8} />
      </div>

    </>
  );
}
export default DopamineMemory;
