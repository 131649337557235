/* eslint-disable default-param-last */
const initialState = {
  name: 'Аноним',
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_NAME':
      return { ...state, name: `${action.payload} великий!` };

    default:
      return state;
  }
}

export default userReducer;
