/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const dopamineSlice = createSlice({
  name: 'dopamine',
  initialState,
  reducers: {
    dopamineslice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { dopamineslice } = dopamineSlice.actions;

export default dopamineSlice.reducer;
