/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopaminePersonality({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  const [checked15, setChecked15] = useState(false);
  const [checked16, setChecked16] = useState(false);
  const [checked17, setChecked17] = useState(false);
  const [checked18, setChecked18] = useState(false);
  const [checked19, setChecked19] = useState(false);
  const [checked20, setChecked20] = useState(false);
  const [checked21, setChecked21] = useState(false);

  useEffect(() => {
    setDopamineresult(checked + checked2 + checked3 + checked4
      + checked5 + checked6 + checked7 + checked8 + checked9 + checked10
       + checked11 + checked12 + checked13 + checked14 + checked15
        + checked16 + checked17 + checked18 + checked19 + checked20 + checked21);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="Я очень властный человек" checked={checked} setChecked={setChecked} />
        <Block text="Иногда я не замечаю своих чувств" checked={checked2} setChecked={setChecked2} />
        <Block text="Мне часто трудно слушать других, из за своего мнения" checked={checked3} setChecked={setChecked3} />
        <Block text="Я решаю конфликты силой" checked={checked4} setChecked={setChecked4} />
        <Block text="Я склонен смотреть в будущее" checked={checked5} setChecked={setChecked5} />
        <Block text="Я расчетлив" checked={checked6} setChecked={setChecked6} />
        <Block text="Большинство людей считают меня думающим на несколько шагов вперед" checked={checked7} setChecked={setChecked7} />
        <Block text="Я мечтаю и часто фантазирую" checked={checked8} setChecked={setChecked8} />
        <Block text="Я люблю читать историю и научно-популярные книги" checked={checked9} setChecked={setChecked9} />
        <Block text="я восхищаюсь изобретательностью" checked={checked10} setChecked={setChecked10} />
        <Block text="Я редко сразу определяю проблемных людей" checked={checked11} setChecked={setChecked11} />
        <Block text="Я верю что, если людям нужна моя помощь, то они честны со мной" checked={checked12} setChecked={setChecked12} />
        <Block text="Большинство людей считают меня современным" checked={checked13} setChecked={setChecked13} />
        <Block text="Я могу рационально объяснить большинство своих новых или странных идей" checked={checked14} setChecked={setChecked14} />
        <Block text="Я часто взволнован или раздражен" checked={checked15} setChecked={setChecked15} />
        <Block text="Меня беспокоят или расстраивают мелочи" checked={checked16} setChecked={setChecked16} />
        <Block text="Я мечтаю о неграниченной власти" checked={checked17} setChecked={setChecked17} />
        <Block text="Я люблю тратить деньги" checked={checked18} setChecked={setChecked18} />
        <Block text="Я доминирую над другими в отношениях" checked={checked19} setChecked={setChecked19} />
        <Block text="Я очень требовательный к себе" checked={checked20} setChecked={setChecked20} />
        <Block text="Я агрессивно реагирую на критику, часто занимаю оборонительную позицию перед другими" checked={checked21} setChecked={setChecked21} />
      </div>
    </>
  );
}
export default DopaminePersonality;
