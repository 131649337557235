/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import './button.css';

function Testbutton({ buttonstyle = 'test-button' }) {
  const handleToggleStorage = () => {
    sessionStorage.setItem('start', JSON.stringify(false));
  };
  return (
    <div className="test-button-container">
      <Link to="/partone">
        <button className={buttonstyle} type="button" onClick={handleToggleStorage}>
          Начать тест
        </button>
      </Link>
    </div>
  );
}

export default Testbutton;
