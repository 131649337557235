/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { React, useState } from 'react';
import './Aboutneuro.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MobileView } from 'react-device-detect';
import Testbutton from '../../components/testbutton/button';
import ModalBanner from '../../components/modalbanner/ModalMobileBanner';

function Aboutneuro() {
  const [open, setOpen] = useState(false);
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  setTimeout(() => {
    if (!sessionStorage.getItem('banner')) { sessionStorage.setItem('banner', JSON.stringify(true)); }
  }, 1500);
  setTimeout(() => {
    setOpen(true);
  }, 4000);
  return (
    <main className="main-des">
      <MobileView>
        <ModalBanner open={open} setOpen={setOpen} />
      </MobileView>
      <Helmet>
        <title>Что такое нейромедиаторы</title>
        <meta
          name="description"
          content="Нейромедиаторы — это нервные клетки сообщаются между собой с помощью отростков — аксонов и дендритов."
        />
      </Helmet>
      <div className="aboutneuro-container">
        <br />
        <div className="aboutneuro-alltext">
          Нейромедиаторы — это праздник, который всегда с тобой. Мы постоянно слышим о том, что именно они дарят чувства радости и удовольствия,
          но мало знаем о том, как они работают.
        </div>
        <br />
        <div className="aboutneuro-image-container">
          <img src="img/aboutone.jpg" alt="gamk" className="aboutneuro-image-image" />
        </div>
        <br />
        <h1 className="aboutneuro-h1">Как работают нейромедиаторы</h1>
        <br />
        <div className="aboutneuro-alltext">
          Нервные клетки сообщаются между собой с помощью отростков — аксонов и дендритов.
          Между ними зазор — так называемая синаптическая щель. Именно здесь и происходит взаимодействие нейронов.
          Медиаторы синтезируются в клетке и доставляются в окончание аксона — к пресинаптической мембране.
          <br />
          <br />
          Там под действием электрических импульсов они попадают в синаптическую щель и активируют рецепторы следующего нейрона. После активации рецепторов нейромедиатор возвращается обратно в клетку (происходит так называемый обратный захват) или разрушается.
          Сами нейромедиаторы не являются белками, поэтому не существует «гена дофамина» или «гена адреналина».
          Белки выполняют всю вспомогательную работу: белки-ферменты синтезируют вещество нейромедиатора, белки-транспортеры отвечают за доставку, белки-рецепторы активируют нервную клетку.
          За правильную работу одного нейромедиатора могут отвечать несколько белков — а значит, несколько разных генов.
        </div>
        <br />
        <h1 className="aboutneuro-neuroheader">Дофамин</h1>
        <div className="aboutneuro-image-container">
          <img src="img/dop-min.png" alt="gamk" className="aboutneuro-image-image-small" />
        </div>
        <br />
        <div className="aboutneuro-alltext">
          За счет активации нейронов в разных областях мозга дофамин играет несколько ролей. Во-первых, он отвечает за двигательную активность и дарит радость движения.
          Во-вторых, дает ощущение почти детского восторга от изучения нового — и стремление поиска новизны.
          В-третьих, дофамин выполняет важную функцию вознаграждения и подкрепления мотивации: как только мы делаем что-то полезное
          для жизни человеческого вида, нейроны выдают нам приз — чувство удовлетворенности (иногда его называют удовольствием).
          <br />
          <br />
          На базовом уровне мы получаем награду за простые человеческие радости — еду и секс, но в целом варианты достижения
          удовлетворения зависят от вкусов каждого — кому-то «морковка» достанется за дописанный код, кому-то — за вот эту статью.
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Система вознаграждения связана с обучением: человек получает удовольствие, а в его мозгу формируются новые причинно-следственные ассоциации.
          И потом, когда удовольствие пройдет и встанет вопрос, как его получить снова, возникнет простое решение — написать еще одну статью.
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Дофамин выглядит как отличный стимулятор для работы и учебы, а также идеальный наркотик — именно с действием дофамина связано большинство наркотиков (амфетамин, кокаин),
          вот только есть серьезные побочные эффекты. «Передозировка» дофамина ведет к шизофрении (мозг работает настолько активно,
          что это начинает проявляться в слуховых и зрительных галлюцинациях), а недостаток — к депрессивному расстройству или развитию болезни Паркинсона.
        </div>
        <br />
        <div className="aboutneuro-neuroresult">
          Как нейромедиатор оказывает влияние на:
          формирование мотивации,
          чувство удовольствия,
          ощущение награды и желания,
          эмоциональные реакции, сопровождающие двигательную активность.
        </div>
        <h5 className="aboutneuro-h1">Серотонин</h5>
        <div className="aboutneuro-image-container">
          <img src="img/ser-min.png" alt="gamk" className="aboutneuro-image-image-small" />
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Мы привыкли слышать о нем как о «гормоне счастья», при этом серотонин — никакой не гормон, и со «счастьем» всё не так однозначно. Серотонин — это нейромедиатор,
          который не столько приносит положительные эмоции, сколько снижает восприимчивость к отрицательным. Он оказывает поддержку «соседним» нейромедиаторам — норадреналину и дофамину;
          серотонин задействован в двигательной активности, снижает общий болевой фон, помогает организму в борьбе против воспаления. Также серотонин повышает точность передачи активных сигналов в мозге и помогает сконцентрироваться.
        </div>
        <br />
        <div className="aboutneuro-alltext">
          95% этого вещества вырабатывается слизистой оболочкой кишечника и лишь 5% — в головном мозге.
          Переизбыток серотонина (например, при употреблении ЛСД) увеличивает «громкость» вторичных сигналов в мозге, и возникают галлюцинации.
          Недостаток серотонина и нарушение баланса между позитивными и негативными эмоциями — основная причина депрессии.
        </div>
        <br />
        <div className="aboutneuro-neuroresult">
          Серотонин улучшает память, внимание, восприятие
          ускоряет и облегчает движения;
          снижает болевой порог;
          контролирует либидо и репродуктивную функцию;
          обеспечивает полноценный сон;
          участвует в синтезе гормонов гипофиза.
        </div>
        <h5 className="aboutneuro-h1">Ацетилхолин</h5>
        <div className="aboutneuro-image-container">
          <img src="img/acit-min.png" alt="gamk" className="aboutneuro-image-image-small" />
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Это первый нейромедиатор, который открыли ученые. Он отвечает за передачу импульсов двигательными нейронами — а значит, за все движения человека.
          В центральной нервной системе нейромедиатор берет на себя стабилизирующие функции: выводит мозг из состояния покоя, когда необходимо действовать,
          и наоборот, тормозит передачу импульсов, когда необходимо сосредоточиться. В этом ему помогают два типа рецепторов — ускоряющие никотиновые и
          тормозящие мускариновые.
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Ацетилхолин играет важную роль в процессе обучения и формирования памяти. Для этого требуется как способность фокусировать внимание (и тормозить передачу отвлекающих импульсов),
          так и способность переключаться с одного предмета на другой (и ускорять реакцию). Активная работа мозга, например, при подготовке к экзамену или годовому отчету, приводит к повышению уровня ацетилхолина.
          <br />
          Если мозг долгое время бездействует, специальный фермент ацетилхолинэстераза разрушает медиатор, и действие ацетилхолина слабеет. Идеальный для учебы, ацетилхолин будет плохим помощником в стрессовых ситуациях:
          это медиатор размышления, но не решительных действий.
        </div>
        <br />
        <div className="aboutneuro-neuroresult">
          Ацетилхолин связан с функциями памяти.
          Играет важную роль в засыпании и пробуждении, концентрации внимания.
          Участие в передаче нервного возбуждения в ЦНС.
        </div>
        <h5 className="aboutneuro-h1">Гамма-аминомасляная кислота</h5>
        <div className="aboutneuro-image-container">
          <img src="img/gamk-min.png" alt="gamk" className="aboutneuro-image-image-small" />
        </div>
        <br />
        <div className="aboutneuro-alltext">
          У каждой инь есть свой ян, и у глутамата есть вечный его противник, с которым он тем не менее неразрывно связан. Речь идет о главном тормозном нейромедиаторе —
          гамма-аминомасляной кислоте (ГАМК или GABA). Так же как и глутамат, ГАМК не вносит новых цветов в палитру мозговой активности, а только регулирует активность других
          нейронов. Так же как и глутамат, ГАМК охватил сетью своих рецепторов около 40% нейронов головного мозга. И глутамат, и ГАМК синтезируются из глутаминовой кислоты и по
          существу являются продолжением друг друга.
        </div>
        <br />
        <div className="aboutneuro-alltext">
          Для описания эффекта ГАМК идеально подходит поговорка «тише едешь — дальше будешь»: тормозящий эффект медиатора позволяет лучше сосредоточиться. ГАМК снижает активность
          самых разных нейронов, в том числе связанных с чувством страха или тревоги и отвлекающих от основной задачи. Высокая концентрация ГАМК обеспечивает спокойствие и
          собранность. Снижение концентрации ГАМК и нарушение баланса в вечном сопротивлении с глутаматом приводит к синдрому дефицита внимания (СДВГ). Для повышения уровня
          ГАМК хорошо подходят прогулки, йога, медитации, для снижения — большинство стимуляторов.
        </div>
        <br />
        <div className="aboutneuro-neuroresult">
          ГАМК  - это спокойствие!
          Отвечает за то, чтобы наши мозги не «перегорели»
        </div>
        <div className="aboutneuro-linkdiv">
          <a href="https://habr.com/ru/companies/atlasbiomed/articles/" className="aboutneuro-linktext" target="_blank" rel="nofollow noopener noreferrer">* В описании используется информация с блога компании Атлас</a>
        </div>
        <div className="aboutneuro-starttest">
          <Testbutton buttonstyle="aboutneuro-buttontest" />
        </div>
        <br />
      </div>
      <Link
        className="aboutneuro-return"
        to="/"
      >
        Вернуться на главную
      </Link>
    </main>
  );
}
export default Aboutneuro;
