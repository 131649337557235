/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopaminePhysical({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  useEffect(() => {
    setDopamineresult(checked + checked2 + checked3 + checked4
      + checked5 + checked6 + checked7 + checked8 + checked9);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="Я не могу без сахара" checked={checked} setChecked={setChecked} />
        <Block text="У меня снизилось либидо" checked={checked2} setChecked={setChecked2} />
        <Block text="Я стал слишком много спать" checked={checked3} setChecked={setChecked3} />
        <Block text="У меня есть признаки алкогольной или наркотической зависимости" checked={checked4} setChecked={setChecked4} />
        <Block text="В последнее время я чувствую себя измотанным без причины" checked={checked5} setChecked={setChecked5} />
        <Block text="Я всегда имел проблемы с лишним весом" checked={checked6} setChecked={setChecked6} />
        <Block text="Секс последнее время мне почти не интересен" checked={checked7} setChecked={setChecked7} />
        <Block text="Вставать с утра для меня не проблема" checked={checked8} setChecked={setChecked8} />
        <Block text="У меня были проблемы с кокаином, амфетамином или экстази" checked={checked9} setChecked={setChecked9} />
      </div>

    </>
  );
}
export default DopaminePhysical;
