/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopaminePhysical({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);
  const [checked12, setChecked12] = useState(false);
  const [checked13, setChecked13] = useState(false);
  const [checked14, setChecked14] = useState(false);
  useEffect(() => {
    setDopamineresult(checked + checked2 + checked3 + checked4
      + checked5 + checked6 + checked7 + checked8 + checked9 + checked10
       + checked11 + checked12 + checked13 + checked14);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="В основном у меня много энергии" checked={checked} setChecked={setChecked} />
        <Block text="У меня часто повышено давление" checked={checked2} setChecked={setChecked2} />
        <Block text="Иногда я чувствую мощный прилив сил" checked={checked3} setChecked={setChecked3} />
        <Block text="У меня бессонница" checked={checked4} setChecked={setChecked4} />
        <Block text="Физические нагрузки дают мне заряд бодрости на целый день" checked={checked5} setChecked={setChecked5} />
        <Block text="Обычно мне не нужен кофе, чтобы взбодриться по утрам" checked={checked6} setChecked={setChecked6} />
        <Block text="Мои вены видны и имеют четко выраженные формы" checked={checked7} setChecked={setChecked7} />
        <Block text="У меня часто бывает немного повышенная температура тела" checked={checked8} setChecked={setChecked8} />
        <Block text="Часто я обедаю во время работы" checked={checked9} setChecked={setChecked9} />
        <Block text="Чем больше секса, тем лучше" checked={checked10} setChecked={setChecked10} />
        <Block text="У меня вспыльчивый характер" checked={checked11} setChecked={setChecked11} />
        <Block text="Я ем только когда я голоден" checked={checked12} setChecked={setChecked12} />
        <Block text="Я люблю боевики" checked={checked13} setChecked={setChecked13} />
        <Block text="Физические упражнения заставляют меня чувствовать себя сильным" checked={checked14} setChecked={setChecked14} />
      </div>

    </>
  );
}
export default DopaminePhysical;
