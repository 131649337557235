import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein, badge) {
  return {
    name, calories, fat, carbs, protein, badge,
  };
}

const rows = [
  createData(
    'Кальций',
    '500',
    '750',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0f3d0b3c0a390c31010138&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_solgar-kalcij-magnij-vit-d3-tab-n150-0001461.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable1&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2F21st-century-kaltsiy-magniy-tsink-i-vitamin-d3-90-tabletok',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fprostye-resheniya-liposomalnyy-kaltsiy-kapsuly-60-sht-2412%2F',
  ),
  createData(
    'Рыбный жир омега-3',
    '500',
    '1000',
    '2000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=00320433053704380b0a3f&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_omega-3-now-kaps-1400mg-n100-bad-0090520.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable2&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fwileys-finest-zhir-dikih-alyaskinskih-ryb-peak-epk-1000-mg-30-rybnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Forzax-fish-oyl-rybiy-zhir-kapsuly-120-sht-3069%2F',
  ),
  createData(
    '5-НТР Триптофан',
    '100',
    '200',
    '500',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0d3f093e083b0f30040f39&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_nau-5-ntr-l-5-gidroksitriptofan-kaps-440mg-n30-bad-0106520.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable3&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-5-gidroksitriptofan-5-htp-50-mg-30-rastitelnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmoloday-l-triptofan-kapsuly-60-sht-3127%2F',
    'Для серотонина',
  ),
  createData(
    'Магний',
    '200',
    '400',
    '600',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=03310730063501390d0736&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_magnij-v6-jevalar-tab-1-25g-n60-bad-0091308.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable4&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-magniy-v-kapsulah-400-mg-180-rastitelnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fbiomins-magniya-bisglitsinat-kapsuly-60-sht-2652%2F',
  ),
  createData(
    'Мелатонин',
    '0.5',
    '1-2',
    '2-6',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0b390f380e3d0a32050d3d&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_kvin-vitamins-melatonin-1mg-kaps-0-48g-n60-bad-0129450.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable5&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Flife-extension-bioaktivnye-molochnye-peptidy-30-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmaxler-melatonin-tabletki-120-sht-209%2F',
    'для хорошего сна',
  ),
  createData(
    'В6 - Пиридоксин',
    '5',
    '10',
    '50',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0d3f093e083e0b370e0637&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v6-piridoksin-2mg-blagomin-kapsuly-0-25g-40sht-0184396.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable6&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-b6-pyridoxin-100-mg-100-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-magniy-b6-piridoksin-kapsuly-60-sht-1293%2F',
    'Для метаболизма',
  ),
  createData(
    'Цинк',
    '15',
    '30',
    '45',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=013305320432053f060b3c&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_selen-cink-jevalar-kapsuly-0-75g-60sht-0184214.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=sertable7&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-tsink-50-mg-250-tabletok',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=seratable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Favicenna-tsink-pikolinat-tabletki-60-sht-499%2F',
  ),
];

export default function SerotonineTable() {
  const [getcolorfromSession] = useState(Number(sessionStorage.getItem('sercount')));
  const [isbackColor] = useState(getcolorfromSession);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 290 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 12, fontWeight: 'bold', backgroundColor: '#FCD0D3' }}>Ваш % дефицита</TableCell>
            <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>25% </TableCell>
            <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>60% </TableCell>
            <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>90% </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 12, textAlign: 'center', color: '#000000', backgroundColor: '#eff6fa',
                }}
              >
                <div className="result-tableprice-firstcolumn">
                  <div className="result-tableprice-firstcolumn-name">
                    {row.badge ? <div className="result-tableprice-firstcolumn-badge">{row.badge}</div> : null}
                    {row.name}
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: 9, background: '#4189b1', paddingTop: 1, marginTop: 1,
                    }}
                    href={row.protein}
                    target="_blank"
                  >
                    Описание и цена
                  </Button>
                </div>
              </TableCell>
              <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.calories}</TableCell>
              <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.fat}</TableCell>
              <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
