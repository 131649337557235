/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BsQuestionSquare } from 'react-icons/bs';
import '../alert.css';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);
  const handleToggle = () => {
    sessionStorage.setItem('is-open-two', JSON.stringify(false));
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  if (sessionStorage.getItem('is-open-two') === JSON.stringify(true)) {
    return (
      <div>
        <div className="alert-button">
          <div className="alert-button-icon"><BsQuestionSquare onClick={handleClickOpen} /></div>
        </div>
        <Dialog
          open={open}
          onClose={handleToggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Инструкция
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="alert-dialog-description">
              Это вторая часть теста, в которой мы определим дефицит нейромедиаторов.
              <br />
              <br />
              После прохождения вы получите рекомендации, исходя из вашего возможного дефицита нейромедиаторов.
              <br />
              <br />
              Ответьте на вопросы с точки зрения того, что вы чувствуете большую часть времени.
              <br />
              <br />
              Например, если вы плохо спали ночью и сегодня чувствуете усталость, ответьте на вопросы,
              исходя из того, как вы себя обычно чувствуете.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToggle} autoFocus>
              Понятно
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
