/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholinePersonality({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7 + checked8);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="Я редко испытываю радость" checked={checked} setChecked={setChecked} />
        <Block text="Я стал чувствать отчаяние" checked={checked2} setChecked={setChecked2} />
        <Block text="Чтобы чувствать себя безопасно, я никогда не рассказывая о себе много" checked={checked3} setChecked={setChecked3} />
        <Block text="Мне удобнее работать в одиночку, чем в компании" checked={checked4} setChecked={setChecked4} />
        <Block text="Я не злюсь из-за надоедливых вещей" checked={checked5} setChecked={setChecked5} />
        <Block text="Я легко сдаюсь и склонен быть покорным" checked={checked6} setChecked={setChecked6} />
        <Block text="Я редко испытываю страсть к чему-либо" checked={checked7} setChecked={setChecked7} />
        <Block text="Мне нравится рутина" checked={checked8} setChecked={setChecked8} />
      </div>

    </>
  );
}
export default AcetylcholinePersonality;
