/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './ResultDopamine.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, PureComponent } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
import BannerFirstResult from '../../../components/banner/BannerFirstResult';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function ResultAcetylcholine() {
  showRedirectModal();
  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [dopaminecount * 2, acetylcholinecount * 2, gabacount * 2, serotoninecount * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  useEffect(() => {
    sessionStorage.setItem('first-result', JSON.stringify('/acetilholine'));
  });
  // This is a closure function test
  function getImage(path) {
    return function imageUrl(url) {
      return `${path}${url}`;
    };
  }
  const testGetImage = getImage('https://neuroapi.vercel.app/');
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Первая часть теста пройдена
            <div className="result-endtest-textsmall"> Ознакомьтесь с результатами и переходите ко второй части, когда будете готовы</div>
          </div>
          <div className="figure-div">
            <figure className="figure-blank">
              <img src={testGetImage('about')} alt="" className="random-image" />
              <figcaption>
                У вас доминирует ацетилхолиновая природа
              </figcaption>
            </figure>
          </div>
          <div className="pie">
            <Radar data={data} />
          </div>
          <aside>
            Если у вас ацетилхолиновая природа, вы умеете работать со своими чувствами и воспринимаете мир с сенсорной точки зрения.
            <br />
            Вы очень креативны и открыты для новых идей.
          </aside>
          <div className="div-text">
            Вы сообразительны и всегда принимаете во внимание чувства и эмоции других людей. Вы стремитесь делать все как можно лучше, независимо от того, сколько усилий это требует.
            Вы гибки, креативны и спонтанны, готовы пробовать все интересное и новое.
            <br />
            <br />
            Если Ацетилхолин находится в норме, то вы интуитивны и изобретательны. Получаете удовольствие от всего, что связано со вербальностью, идеями и общением.
            (Ацетилхолин в значительной степени вырабатывается в теменных долях мозга, отвечающих за речь, интеллект и понимание).
            <br />
            <br />
            <p className="p-result">
              Уровень ацетилхолина связан с высокой скоростью работы мозга, что влияет на творчество, поэтому у художников, писателей,
              и актеров часто доминирует ацетилхолин.
            </p>
            <br />
            Вы чрезвычайно общительны, даже харизматичны. Любите встречаться и заводить новых друзей. Вы кажетесь окружающим искренними
            и приземленными. Люди находят вас очаровательным, отношения даются вам легко.
            <br />
            <br />
            {/* <BannerFirstResult /> */}
            Вы оптимист и видите в людях возможности.Внимательны к потребностям детей и романтичны со своей второй половинкой.
            Хорошо запоминаете чувства и реакции людей.
          </div>
          <Typography variant="h6" gutterBottom>
            Избыток ацетилхолина
          </Typography>
          <aside>
            При избытке ацетилхолина вы можете отдать слишком много себя, вплоть до того, что перестанете учитывать собственные потребности.
          </aside>
          <div className="div-text">
            Вы можете чувствовать, что мир использует вас, или стать параноиком.
            В результате вы можете оказаться в социальной изоляции. Возжможны панические расстройства, маниакальные эпизоды или тревога.
          </div>
        </div>
      </div>
      <div className="result-nexttest-text">
        <Link
          to="/parttwo"
        >
          Вторая часть теста
        </Link>
      </div>
    </main>
  );
}
export default ResultAcetylcholine;
