/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotonineCharacter({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3 + checked4
       + checked5 + checked6 + checked7 + checked8
        + checked9 + checked10);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я всегда имею запасной вариант" checked={checked} setChecked={setChecked} />
        <Block text="Я не люблю долго работать" checked={checked2} setChecked={setChecked2} />
        <Block text="Я считаю, что вещи должны быть функциональными" checked={checked3} setChecked={setChecked3} />
        <Block text="Я оптимист" checked={checked4} setChecked={setChecked4} />
        <Block text="я живу одним днем" checked={checked5} setChecked={setChecked5} />
        <Block text="Когда мне очень плохо, я молюсь" checked={checked6} setChecked={setChecked6} />
        <Block text="У меня нет высоких моральных и этических ценностей" checked={checked7} setChecked={setChecked7} />
        <Block text="Я делаю то, что хочу, когда хочу" checked={checked8} setChecked={setChecked8} />
        <Block text="Я не забочусь о том, чтобы быть совершенным, просто живу своей жизнью" checked={checked9} setChecked={setChecked9} />
        <Block text="Экономия для лохов" checked={checked10} setChecked={setChecked10} />
      </div>

    </>
  );
}
export default SerotonineCharacter;
