/* eslint-disable max-len */
import './ResultDopamine.css';
import React, { useRef } from 'react';
import Dopaminetable from './Content/Dopaminetable';
import ResultChecker from './ResultChecker';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';
// import BannerResult from '../../../components/banner/BannerResult';
import ShareBlock from '../../../components/shareblock/shareBlock';

function ResultDopaminetwo() {
  showRedirectModal();
  const ref = useRef(null);
  return (
    <main className="main-des">
      <div id="partone" className="main-partone">
        <div className="result-main-text">
          <div className="result-endtest-text">
            Тест пройден
            <div className="result-endtest-textsmall">
              Теперь вы знаете ваш доминантный тип и дефицит нейромедиаторов.
              Ознакомьтесь с таблицей и сохраните себе результат
            </div>
          </div>
        </div>
        <ResultChecker />
        <div className="main-partone" ref={ref}>
          <div className="div-text-attention">По результатам теста у вас дефицит дофамина</div>
          <div className="div-text">Ранними предупреждающими признаками являются потеря энергии, усталость, вялость, потеря памяти или хандра. </div>
          <div className="div-text-table">Для вас подготовлена таблица аминокислот и витаминов в соответствии с дефицитом нейромедиаторов.  </div>
          {/* <BannerResult /> */}
          <div className="div-text"><Dopaminetable /></div>

          <div className="div-text">
            Диетические добавки также могут повысить дофамин. Увеличьте потребление фенилаланина и тирозина ,используйте заменитель сахара,
            ешьте курицу, творог, яйца, соевые продукты, индейку, грецкие орехи.
            <br />
            <br />
            Чтобы предотвратить дофаминовое выгорание, практикуйте глубокое дыхание , смотрите фильмы и сериалы с минимальным количеством насилия,
            регулярно занимайтесь спортом. Бросайте курить, если вы курите.
            <br />
            <br />
            Чашка или две кофеина утром — это очень распространенный способ
            увеличить дофамин, однако это может вызвать бессонницу или тремор, если доза слишком высока или принята слишком поздно в течение дня.
          </div>
        </div>
        <ShareBlock urlProps="https://bnlab.ru/resultdopaminetwo" pdfSource="/pdf/Dopamine.pdf" />
        {/* <Link type="button" to="/pdf/Dopamine.pdf" target="_blank" download className="div-text-save">PDF</Link> */}

      </div>
    </main>
  );
}
export default ResultDopaminetwo;
