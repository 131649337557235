/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../ResultDopamine.css';
import React, { useState, useEffect, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function SerotonineforResult() {
  // const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  // const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  // const gabacount = useSelector((state) => state.gabacounter.value);
  // const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  const [ddata] = useState(Number(sessionStorage.getItem('dgraph')));
  const [adata] = useState(Number(sessionStorage.getItem('agraph')));
  const [gdata] = useState(Number(sessionStorage.getItem('ggraph')));
  const [sdata] = useState(Number(sessionStorage.getItem('sgraph')));

  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [ddata * 2, adata * 2, gdata * 2, sdata * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  return (
    <div id="partone" className="main-partone">
      <div className="result-main-text">
        <div className="figure-div">
          <figure className="figure-blank">
            <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
            <figcaption>
              У вас серотониновая природа
            </figcaption>
          </figure>
        </div>
        <div className="pie">
          <Radar data={data} />
        </div>
        <aside>
          Если у вас серотониновая природа, вы относитесь к 17% населения, которые действительно умеют получать удовольствие от жизни.
        </aside>
        <div className="div-text">
          Серотонин связан с дельта-волнами ( которые в изобилии вырабатываются, когда мы спим ) и
          влияет на нашу способность отдыхать, восстанавливаться и чувстовать себя в безопасности.
          <br />
          <br />
          Вы реалист, остро реагируете на сенсорную информацию, но также можете быть импульсивным. Вы любите участвовать в событиях из-за «любви к игре»,
          а не воспринимая их, как средство для достижения цели.
          Достижение для вас означает добиться чего либо в моменте. Вам нравятся перемены — вы будете чередовать задачи и находить
          новые способы для их решения. Будете пробовать новое, находить хобби и каждый год планировать интересный отпуск.
          <br />
          <br />
          В уравновешенном состоянии человек с серотониновой природой восприимчив к раздражителям, находится в равновесии как с разумом, так и телом,
          часто физически скоординирован и очень находчив. Вас не останавливает борьба и не смущают неудачи.
          <p className="p-result">
            Если где-то есть адреналин, вы найдете его, будь то вечеринки, бокс,
            видеоигры, казино, альпинизм, прыжки с парашютом, катание на лыжах или охота на крокодилов.
          </p>
          Вы пренебрегаете порядком и рутиной, а при вашей любви к независимости это может поставить в тупик ваши самые близкие отношения.
        </div>
      </div>
    </div>
  );
}
export default SerotonineforResult;
