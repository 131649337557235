/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../testcontent.css';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GabaCharacter from './Gaba/GabaCharacter';
import GabaMemory from './Gaba/GabaMemory';
import GabaPersonality from './Gaba/GabaPersonality';
import GabaPhysical from './Gaba/GabaPhysical';
import { gabaslice } from '../../../store/slices/gabaSlice';
import ElevateAppBar from './Sticky';
import TestStepper from '../UI/Teststepper';
import NextButtonBlock from '../UI/Testnextblock';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';

const steps = ['ГАМК - память и внимание', 'ГАМК - Физиология', 'ГАМК - Характер', 'ГАМК - Личность'];

function Partonecontent3() {
  showRedirectModal();
  const [gabaresult, setGabaresult] = useState(0);
  const [gabaresult2, setGabaresult2] = useState(0);
  const [gabaresult3, setGabaresult3] = useState(0);
  const [gabaresult4, setGabaresult4] = useState(0);
  const gabasum = gabaresult + gabaresult2 + gabaresult3 + gabaresult4;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(gabaslice(gabasum));
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 10; // если нужно ставить OPTIONAL для возможности пропустить

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigate = useNavigate();

  if (activeStep === steps.length) {
    setTimeout(() => {
      navigate('/partone4');
    }, 4000);
  }
  return (
    <>
      <ElevateAppBar />
      <Box sx={{ width: '100%' }} className="partonebox">
        <TestStepper activeStep={activeStep} isStepOptional={isStepOptional} isStepSkipped={isStepSkipped} steps={steps} />
        { activeStep === steps.length && (
        <>
          <div className="partonenext-content">
            <div className="partonenext-text">
              Данные по ГАМК собраны и обрабатываются
            </div>
          </div>
          <div className="partone-loading">
            <img src="img/result.svg" alt="loading" />
          </div>
        </>
        )}
        { activeStep === 0 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <GabaMemory setGabaresult={setGabaresult} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 1 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <GabaPhysical setGabaresult={setGabaresult2} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 2 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <GabaCharacter setGabaresult={setGabaresult3} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 3 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <GabaPersonality setGabaresult={setGabaresult4} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
      </Box>
    </>
  );
}

export default Partonecontent3;
