import './scroll.css';
import React from 'react';

function Scroller() {
  return (
    <div className="scrolldown">
      <div className="chevrons">
        <div className="chevrondown" />
        <div className="chevrondown" />
      </div>
    </div>
  );
}
export default Scroller;
