/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopamineMemory({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  useEffect(() => {
    setDopamineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="У меня постоянные проблемы с вниманием и концентрацией" checked={checked} setChecked={setChecked} />
        <Block text="Мне нужен кофеин, чтобы проснуться" checked={checked2} setChecked={setChecked2} />
        <Block text="Я перестал думать достаточно быстро" checked={checked3} setChecked={setChecked3} />
        <Block text="Концентрация внимания стала немного хуже" checked={checked4} setChecked={setChecked4} />
        <Block text="У меня проблемы с выполнением задачи, даже если она мне интересна" checked={checked5} setChecked={setChecked5} />
        <Block text="Новые идеи стали приходить ко мне реже" checked={checked6} setChecked={setChecked6} />
      </div>
    </>
  );
}
export default DopamineMemory;
