/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../ResultDopamine.css';
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function AcetilholineforResult() {
  const [ddata] = useState(Number(sessionStorage.getItem('dgraph')));
  const [adata] = useState(Number(sessionStorage.getItem('agraph')));
  const [gdata] = useState(Number(sessionStorage.getItem('ggraph')));
  const [sdata] = useState(Number(sessionStorage.getItem('sgraph')));

  const data = {
    labels: ['Дофамин', 'Ацетилхолин', 'Гамк', 'Серотонин'],
    datasets: [
      {
        label: 'Ваш результат',
        data: [ddata * 2, adata * 2, gdata * 2, sdata * 2],
        backgroundColor: 'rgb(0, 121, 214)',
        borderColor: '#0E294B',
        borderWidth: 0.7,
      },
    ],
  };
  return (
    <div id="partone" className="main-partone">
      <div className="result-main-text">
        <div className="figure-div">
          <figure className="figure-blank">
            <img src="https://neuroapi.vercel.app/about" alt="" className="random-image" />
            <figcaption>
              У вас доминирует ацетилхолиновая природа
            </figcaption>
          </figure>
        </div>
        <div className="pie">
          <Radar data={data} />
        </div>
        <aside>
          Если у вас ацетилхолиновая природа, вы умеете работать со своими чувствами и воспринимаете мир с сенсорной точки зрения.
          <br />
          Вы очень креативны и открыты для новых идей.
        </aside>
        <div className="div-text">
          Вы сообразительны и всегда принимаете во внимание чувстаа и эмоции других людей. Вы стремитесь делать все как можно лучше, независимо от того, сколько усилий это требует.
          Вы гибки, креативны и спонтанны, готовы пробовать все интересное и новое.
          <br />
          <br />
          Если Ацетилхолин находится в норме, то вы интуитивны и изобретательны. Получаете удовольствие от всего, что связано со вербальностью, идеями и общением.
          (Ацетилхолин в значительной степени вырабатывается в теменных долях мозга, отвечающих за речь, интеллект и понимание).
          <br />
          <br />
          <p className="p-result">
            Уровень ацетилхолина связан с высокой скоростью работы мозга, что влияет на творчество, поэтому у художников, писателей,
            и актеров часто доминирует ацетилхолин.
          </p>
          <br />
          Вы чрезвычайно общительны, даже харизматичны. Любите встречаться и заводить новых друзей. Вы кажетесь окружающим искренними
          и приземленными. Люди находят вас очаровательным, отношения даются вам легко.
          <br />
          <br />
          Вы оптимист и видите в людях возможности.Внимательны к потребностям детей и романтичны со своей второй половинкой.
          Хорошо запоминаете чувства и реакции людей.
        </div>
      </div>
    </div>
  );
}
export default AcetilholineforResult;
