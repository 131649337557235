/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { React, useState } from 'react';
import './Licenses.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Licenses() {
  sessionStorage.setItem('start', JSON.stringify(false)); // this is a mark for first contact allowed page
  return (
    <main className="main-des">
      <Helmet>
        <title>Атрибуция</title>
        <meta
          name="description"
          content="Атрибуция"
        />
      </Helmet>
      <div className="license-main">
        <article className="license-article">
          <h2>АТРИБУЦИЯ</h2>
          <br />
          <div>
            Часть контента представлена freepik.com по Free for commercial use WITH ATTRIBUTION license.
          </div>
          Пример лицензии:
          <div className="license-image">
            <img src="img/brain.jpg" alt="brain" />
          </div>
          <div>
            Если Контент нарушает ваши авторские права, то,
            пожалуйста, сообщите нам об этом, используя at@bnlab.ru или
            {' '}
            {' '}
            <Link
              to="/contacts"
            >
              <b>ФОРМУ ОБРАТНОЙ СВЯЗИ</b>
            </Link>
          </div>
        </article>
      </div>
    </main>
  );
}
