/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import AlertDialog from './Alert';
import '../Sticky.scss';
import Newburgermenutest from '../../../components/newburger/newburgermenutest';

export default function ElevateAppBar() {
  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);
  return (
    <AppBar>
      <div className="mainappbar">
        <div className="appbar">
          <div className="appbarmenuandlogo">
            <div id="outer-container">
              <Newburgermenutest pageWrapId="page-wrap" outerContainerId="outer-container" />
              <main id="page-wrap" />
            </div>
            <div className="appbar-logo">
              BNLAB.RU
            </div>
          </div>
          <div className="main-header-one">
            <div className="container">
              <h1 className="neuro-text">
                Дофамин
              </h1>
              <div className="progressbar-container">
                <div className="progressbar-complete" style={{ width: `${dopaminecount * 2}%` }}>
                  <div className="progressbar-liquid" />
                </div>
                <span className="progress">
                  {dopaminecount * 2}
                  %
                </span>
              </div>
            </div>
            <div className="container" id="green">
              <h1 className="neuro-text">Ацетилхолин</h1>
              <div className="progressbar-container">
                <div className="progressbar-complete" style={{ width: `${acetylcholinecount * 2}%` }}>
                  <div className="progressbar-liquid-green" />
                </div>
                <span className="progress">
                  {acetylcholinecount * 2}
                  %
                </span>
              </div>
            </div>
            <div className="container">
              <h1 className="neuro-text">Гамк</h1>
              <div className="progressbar-container">
                <div className="progressbar-complete" style={{ width: `${gabacount * 2}%` }}>
                  <div className="progressbar-liquid-blue" />
                </div>
                <span className="progress">
                  {gabacount * 2}
                  %
                </span>
              </div>
            </div>
            <div className="container">
              <h1 className="neuro-text">Серотонин</h1>
              <div className="progressbar-container">
                <div className="progressbar-complete" style={{ width: `${serotoninecount * 2}%` }}>
                  <div className="progressbar-liquid-yellow" />
                </div>
                <span className="progress">
                  {serotoninecount * 2}
                  %
                </span>
              </div>
            </div>
          </div>
          <div className="button-instruction"><AlertDialog /></div>
        </div>
      </div>
    </AppBar>
  );
}
