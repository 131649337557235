/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function AcetylcholinePhysical({ setAcetylcholineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  useEffect(() => {
    setAcetylcholineresult(checked + checked2 + checked3 + checked4 + checked5 + checked6);
  });

  return (
    <>
      <div className="block-name">АЦЕТИЛХОЛИН - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="У меня бессонница" checked={checked} setChecked={setChecked} />
        <Block text="Мое тело больше не в тонусе" checked={checked2} setChecked={setChecked2} />
        <Block text="Я перестал ходить на тренировки" checked={checked3} setChecked={setChecked3} />
        <Block text="Я люблю жирную еду" checked={checked4} setChecked={setChecked4} />
        <Block text="Я экспериментировал с галлюциногенами или другими похожими веществами" checked={checked5} setChecked={setChecked5} />
        <Block text="У меня появилаь отдышка" checked={checked6} setChecked={setChecked6} />
      </div>
    </>
  );
}
export default AcetylcholinePhysical;
