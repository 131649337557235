import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein, badge) {
  return {
    name, calories, fat, carbs, protein, badge,
  };
}

const rows = [
  createData(
    'Холин',
    '100',
    '200',
    '500',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0f3d0b3c0c3c0b34000a39&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_holin-350mg-jevalar-tabletki-1-2g-60sht-0187648.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable1&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-holin-i-inozitol-100-rastitelnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnutriheal-holin-miks-nootropnyy-kompleks-tabletki-60-sht-1222%2F',
    'Для ацетилхолина',
  ),
  createData(
    'Фосфатидилсерин',
    '50',
    '100',
    '200',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0537013600350538010c3d&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_nau-fuds-fosfatidilserin-kaps-620mg-n60-bad-0092647.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable2&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-phosphatidylserin-100-mg-30-weichkapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fbiomins-omega-3-fosfatidilserin-kapsuly-30-sht-2656%2F',
  ),
  createData(
    'Ацетил-L-Карнитин',
    '250',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0b390f3808380c3b030237&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_l-karnitin-500mg-now-nau-kapsuly-917mg-60sht-0184862.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable3&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-atsetil-l-karnitin-500-mg-50-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmaxler-atsetil-l-karnitin-kapsuly-vegan-100-sht-141%2F',
  ),
  createData(
    'DHA Омега-3',
    '200',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=083a0c3b0d3f0934020b39&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_omega-3-kaps-now-nau-1400mg-200sht-0174069.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable4&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-zhirnye-kisloty-omega-3-180-epk-120-dgk-100-kapsul-2',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Frestartbio-omega-3-kapsuly-90-sht-1693%2F',
    'Для иммунитета',
  ),
  createData(
    'Витамин В1 – тиамин',
    '25',
    '50',
    '100',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0537013606360235070a3f&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v1-tiamin-blagomin-kapsuly-0-25g-40sht-0184395.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable5&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-b1-thiamin-100-mg-250-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupptiamin-gidrohlorid-vitamin-b1-kapsuly-60-sht-1294%2F',
  ),
  createData(
    'Витамин В5',
    '25',
    '50',
    '100',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0c3e083f093b0931030b3a&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v5-gls-kapsuly-400mg-60sht-0184261.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable6&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Flife-extension-pantotenovaya-kislota-vitamin-b5-500-mg-100-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-vitamin-v5-pantotenovaya-kislota-kapsuly-60-sht-459%2F',
  ),
  createData(
    'Таурин',
    '250',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=063402350331063a030d3c&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_taurin-1000-kaps-400mg-n90-bad-0128697.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable7&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-taurin-500-mg-100-rastitelnyh-kapsul-2',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fsupermins-taurin-kapsuly-60-sht-2997%2F',
    'Улучшает память',
  ),
  createData(
    'Гинкго Билоба',
    '50',
    '75',
    '100',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=073503340434013602083e&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_ginkgo-biloba-kompleks-tab-n30-bad-0142702.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable8&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fdoctors-best-ginkgo-s-povyshennoy-siloy-deystviya-120-mg-120-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmindly-ginkgo-biloba-tabletki-60-sht-1851%2F',
  ),
  createData(
    'Корень Женьшеня',
    '100',
    '200',
    '500',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0c3e083f093d0831060a38&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_urban-formula-kompleks-jekstraktov-zhenshenja-jeleuterokokka-i-zelenogo-chaja-kaps-400mg-n60-bad-0148594.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=actable9&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-vollspektrum-wurzel-des-koreanischen-roten-ginsengs-400-mg-90-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=actable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fjoie-de-vivre-zhenshen-kapsuly-30-sht-2207%2F',
    'Против усталости',
  ),

];

export default function Acetilholinetable() {
  const [getcolorfromSession] = useState(Number(sessionStorage.getItem('account')));
  const [isbackColor] = useState(getcolorfromSession);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 290 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 12, fontWeight: 'bold', backgroundColor: '#FCD0D3' }}>Ваш % дефицита</TableCell>
            <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>25% </TableCell>
            <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>60% </TableCell>
            <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>90% </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 12, textAlign: 'center', color: '#000000', backgroundColor: '#eff6fa',
                }}
              >
                <div className="result-tableprice-firstcolumn">
                  <div className="result-tableprice-firstcolumn-name">
                    {row.badge ? <div className="result-tableprice-firstcolumn-badge">{row.badge}</div> : null}
                    {row.name}
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: 9, background: '#4189b1', paddingTop: 1, marginTop: 1,
                    }}
                    href={row.protein}
                    target="_blank"
                  >
                    Описание и цена
                  </Button>
                </div>
              </TableCell>
              <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.calories}</TableCell>
              <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.fat}</TableCell>
              <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
