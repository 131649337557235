/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotonineMemory({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3 + checked4);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Память и внимание</div>
      <div id="partone" className="main-partone">
        <Block text="Я не очень проницателен" checked={checked} setChecked={setChecked} />
        <Block text="У меня плохая зрительная память" checked={checked2} setChecked={setChecked2} />
        <Block text="У меня медленная реакция" checked={checked3} setChecked={setChecked3} />
        <Block text="Я плохо ориентируюсь на местности" checked={checked4} setChecked={setChecked4} />
      </div>

    </>
  );
}
export default SerotonineMemory;
