import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein, badge) {
  return {
    name, calories, fat, carbs, protein, badge,
  };
}

const rows = [
  createData(
    'Фенилаланин(мг)',
    '500',
    '1000',
    '2000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0a380e390f3d0930030b38&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_l-fenilalanin-now-kaps-600mg-n120-bad-0090397.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable1&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Flife-extension-d-l-phenylalanine-d-l-phenylalanin-500-mg-100-pflanzliche-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fsib-kruk-superfud-bodroe-nastroenie-pri-hronicheskoy-ustalosti-poroshok-100-g-1073%2F',
    'Повышает дофамин',
  ),
  createData(
    'Тирозин(мг)',
    '500',
    '1000',
    '2000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0f3d0b3c0c360e38010f37&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_tirozin-kaps-400mg-n90-bad-0150715.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable2&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-l-tirozin-500-mg-120-kapsul',
    // 'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&f_id=25397&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fjoie-de-vivre-tirozin-kapsuly-30-sht-2195%2F',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fjoie-de-vivre-tirozin-kapsuly-30-sht-2195%2F',
  ),
  createData(
    'Метионин(мг)',
    '250',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=043600370f38083504093a&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_prometionin-kaps-350mg-n90-bad-0150712.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable3&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-l-methionin-mit-ajipure-500-mg-60-pflanzliche-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fjoie-de-vivre-lipotropnyy-faktor-kapsuly-120-sht-2415%2F',
  ),
  createData(
    'Родиола(мг)',
    '50',
    '100',
    '200',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0a380e3900330a31080537&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_rodiola-rozovaja-jekstr-zhidk-30ml-0016691.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable4&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fsolaray-superekstrakt-kornya-rodioly-500-mg-60-veganskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fsibvio-rodiola-rozovaya-5-kapsuly-60-sht-589%2F',
  ),
  createData(
    'Пиридоксин(мг)',
    '5',
    '10',
    '50',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0a380e3909390d3a09083d&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v6-piridoksin-2mg-blagomin-kapsuly-0-25g-40sht-0184396.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable5&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-b6-pyridoxin-100-mg-100-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-vitamin-v6-kapsuly-vegan-60-sht-461%2F',
  ),
  createData(
    'Витамины В+(мг)',
    '25',
    '50',
    '100',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0a380e3909390e30030b39&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitaminy-gruppy-v-kompleks-herb-s-herb-c-tabletki-0-19g-60sht-0192213.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable6&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnaturesplus-kompleks-vitaminov-gruppy-b-s-risovymi-otrubyami-90-tabletok',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmindly-active-b-complex-vitaminy-v6-b9-b12-tabletki-60-sht-1853%2F',
    'Обмен веществ',
  ),
  createData(
    'Фосфатидилсерин(мг)',
    '50',
    '100',
    '200',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0537013600350538010c3d&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_nau-fuds-fosfatidilserin-kaps-620mg-n60-bad-0092647.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable7&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-phosphatidylserin-100-mg-30-weichkapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Feqville-kompleks-antioksidantov-s-d3-q10-selenom-omega-3-karnozinom-fosfatidilserinom-kapsuly-30-sht-1032%2F',
  ),
  createData(
    'Гинкго билоба(мг)',
    '50',
    '75',
    '100',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=093b0d3a0c3f0a30050437&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_ginkgo-biloba-herb-s-herb-c-tabletki-750mg-60sht-0193295.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=doptable8&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fmason-natural-whole-herb-ginkgo-biloba-60-capsules',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=doptable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmindly-ginkgo-biloba-tabletki-60-sht-1851%2F',
    'Против тревоги',
  ),
];

export default function Dopaminetable() {
  const [getcolorfromSession] = useState(Number(sessionStorage.getItem('dopcount')));
  const [isbackColor] = useState(getcolorfromSession);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 290 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 12, fontWeight: 'bold', backgroundColor: '#FCD0D3' }}>Ваш % дефицита</TableCell>
            <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>25% </TableCell>
            <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>60% </TableCell>
            <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>90% </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 12, textAlign: 'center', color: '#000000', backgroundColor: '#eff6fa',
                }}
              >
                <div className="result-tableprice-firstcolumn">
                  <div className="result-tableprice-firstcolumn-name">
                    {row.badge ? <div className="result-tableprice-firstcolumn-badge">{row.badge}</div> : null}
                    {row.name}
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: 9, background: '#4189b1', paddingTop: 1, marginTop: 1,
                    }}
                    href={row.protein}
                    target="_blank"
                  >
                    Описание и цена
                  </Button>
                </div>
              </TableCell>
              <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FCD0D3', fontSize: 14 })}>{row.calories}</TableCell>
              <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.fat}</TableCell>
              <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
