import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein, badge) {
  return {
    name, calories, fat, carbs, protein, badge,
  };
}

const rows = [
  createData(
    'Инозитол',
    '500',
    '1000',
    '1000+',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0b390f380e3d0a35020c3f&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_inozitol-elentra-nutrition-kapsuly-770mg-60sht-0194948.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable1&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-inozitol-v-kapsulah-500-mg-100-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-inozitol-kapsuly-60-sht-422%2F',
    'Aнтидепрессант',
  ),
  createData(
    'ГАМ Кислота',
    '100',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0f3d0b3c04330533070e38&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_optimum-system-optimalnaja-sistema-gamk-90-kaps-0171260.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable2&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-gamk-s-vitaminom-b6-500-mg-100-vegetarianskih-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-gaba-gamma-aminomaslyanaya-kislota-kapsuly-60-sht-1408%2F',
    'Повышает ГАМК',
  ),
  createData(
    'Мелатонин',
    '1',
    '3',
    '5',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0b390f380e3d0a32050d3d&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_kvin-vitamins-melatonin-1mg-kaps-0-48g-n60-bad-0129450.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable3&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-sleep-rastitelnaya-smes-dlya-sna-90-rastitelnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fmaxler-melatonin-tabletki-120-sht-209%2F',
    'Для хорошего сна',
  ),
  createData(
    'Витамин В1 – тиамин',
    '200',
    '400',
    '600',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0d3f093e083b0d30090236&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v1-tiamin-blagomin-kapsuly-0-25g-40sht-0184395.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable4&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-b1-thiamin-100-mg-250-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupptiamin-gidrohlorid-vitamin-b1-kapsuly-60-sht-1294%2F',
  ),
  createData(
    'Витамин В6 - Пиридоксин',
    '5',
    '10',
    '50',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=0d3f093e083e0b370e0637&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_vitamin-v6-piridoksin-2mg-blagomin-kapsuly-0-25g-40sht-0184396.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable5&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-b6-pyridoxin-100-mg-100-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fnaturalsupp-magniy-b6-piridoksin-kapsuly-60-sht-1293%2F',
  ),
  createData(
    'Корень валерианы',
    '100',
    '200',
    '500',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=053701360034003a0d033a&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_valeriany-jek-t-tab-20mg-v6-n50-0084524.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable6&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fswanson-baldrianwurzel-475-mg-100-kapseln',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fpravilnye-travy-sbor-antistress-150-g-1668%2F',
  ),
  createData(
    'Пассифлора',
    '200',
    '500',
    '1000',
    // 'https://stvkr.com/v2/click-BPvWq-ApMRY-vAj03-cb80a1df?tl=3&bt=25&prdct=043600370134043e09083b&sa=table&url=https%3A%2F%2Fzdravcity.ru%2Fp_passiflora-jedas-911-gomeopat-granuly-banka-20g-0106447.html',
    // 'https://bywiola.com/g/8osas5s362ee1d1931ac83e83d63b6/?erid=LatgBehRk&subid=gabatable7&ulp=https%3A%2F%2Fwell-be.ru%2Fproduct%2Fnow-foods-passiflora-350-mg-90-rastitelnyh-kapsul',
    'https://ficca2021.com/g/qckpgoxmkzee1d1931aceaa1da88f2/?erid=LatgBsZM1&subid=gabatable&ulp=https%3A%2F%2Fitab.pro%2Fproducts%2Fzade-vital-ekstrakt-passiflory-kapsuly-30-sht-2917%2F',
    'Cнятие стресса',
  ),
];

export default function Gabatable() {
  const [getcolorfromSession] = useState(Number(sessionStorage.getItem('gabacount')));
  const [isbackColor] = useState(getcolorfromSession);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 290 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 12, fontWeight: 'bold', backgroundColor: '#FCD0D3' }}>Ваш % дефицита</TableCell>
            <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>25% </TableCell>
            <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>60% </TableCell>
            <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FC939B', fontSize: 12, fontWeight: 'bold' })}>90% </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: 12, textAlign: 'center', color: '#000000', backgroundColor: '#eff6fa',
                }}
              >
                <div className="result-tableprice-firstcolumn">
                  <div className="result-tableprice-firstcolumn-name">
                    {row.badge ? <div className="result-tableprice-firstcolumn-badge">{row.badge}</div> : null}
                    {row.name}
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: 9, background: '#4189b1', paddingTop: 1, marginTop: 1,
                    }}
                    href={row.protein}
                    target="_blank"
                  >
                    Описание и цена
                  </Button>
                </div>
              </TableCell>
              <TableCell align="right" sx={isbackColor <= 8 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.calories}</TableCell>
              <TableCell align="right" sx={isbackColor > 8 && isbackColor < 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.fat}</TableCell>
              <TableCell align="right" sx={isbackColor >= 16 && ({ backgroundColor: '#FCD0D3', fontSize: 12 })}>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
