/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const gabaSlice = createSlice({
  name: 'gaba',
  initialState,
  reducers: {
    gabaslice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { gabaslice } = gabaSlice.actions;

export default gabaSlice.reducer;
