import React from 'react';
import './BeckBanner.css';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

function BeckBanner() {
  const location = useLocation();
  if (isMobile && location.pathname === '/') {
    return (
      <a
        className="headerbannerblock"
        target="_blank"
        rel="noopener noreferrer"
        href="https://beck.bnlab.ru"
        title="тест бека на депрессию"
      >
        <img
          className="headerbannerimage"
          src="img/ban.webp"
          alt="brain"
        />
        <div className="headerbannertext">
          ТЕСТ БЕКА НА ДЕПРЕССИЮ

        </div>
        <div
          className="bannerstripe"
        >
          НОВОЕ
        </div>
      </a>
    );
  }
  if (!isMobile) {
    return (
      <a
        className="headerbannerblock"
        target="_blank"
        rel="noopener noreferrer"
        href="https://beck.bnlab.ru"
        title="тест бека на депрессию"
      >
        <img
          className="headerbannerimage"
          src="img/ban.webp"
          alt="brain"
        />
        <div className="headerbannertext">
          ТЕСТ БЕКА НА ДЕПРЕССИЮ

        </div>
        <div
          className="bannerstripe"
        >
          НОВОЕ
        </div>
      </a>
    );
  }
}

export default BeckBanner;
