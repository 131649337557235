/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function SerotoninePhysical({ setSerotonineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);

  useEffect(() => {
    setSerotonineresult(checked + checked2 + checked3
   + checked4 + checked5 + checked6 + checked7
    + checked8 + checked9 + checked10);
  });

  return (
    <>
      <div className="block-name">СЕРОТОНИН - Физиология</div>
      <div id="partone" className="main-partone">
        <Block text="Я сильно потею ночью" checked={checked} setChecked={setChecked} />
        <Block text="У меня бессоница" checked={checked2} setChecked={setChecked2} />
        <Block text="Я сплю в разных позах, чтобы чувствовать себя комфортно" checked={checked3} setChecked={setChecked3} />
        <Block text="Я всегда просыпаюсь рано" checked={checked4} setChecked={setChecked4} />
        <Block text="Я часто не могу расслабиться" checked={checked5} setChecked={setChecked5} />
        <Block text="Я часто просыпаюсь ночью" checked={checked6} setChecked={setChecked6} />
        <Block text="Если ночью я проснулся, то с трудом могу снова заснуть" checked={checked7} setChecked={setChecked7} />
        <Block text="Я люблю соленую еду" checked={checked8} setChecked={setChecked8} />
        <Block text="У меня стало меньше энергии для тренировок" checked={checked9} setChecked={setChecked9} />
        <Block text="Я часто чувствую себя грустно" checked={checked10} setChecked={setChecked10} />
      </div>

    </>
  );
}
export default SerotoninePhysical;
