/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function GabaPersonality({ setGabaresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);
  const [checked10, setChecked10] = useState(false);
  const [checked11, setChecked11] = useState(false);

  useEffect(() => {
    setGabaresult(checked + checked2 + checked3 + checked4 + checked5
  + checked6 + checked7 + checked8 + checked9 + checked10 + checked11);
  });

  return (
    <>
      <div className="block-name">ГАМК - Личность</div>
      <div id="partone" className="main-partone">
        <Block text="У меня часто перепады настроения" checked={checked} setChecked={setChecked} />
        <Block text="Мне нравится делать много дел одновременно, но трудно решить, что сделать в первую очередь" checked={checked2} setChecked={setChecked2} />
        <Block text="Я склонен делать что-то только потому, что это приносит удовольствие" checked={checked3} setChecked={setChecked3} />
        <Block text="Когда все скучно, я стараюсь привнести немного азарта" checked={checked4} setChecked={setChecked4} />
        <Block text="У меня часто меняется настроение и мысли" checked={checked5} setChecked={setChecked5} />
        <Block text="Я склонен чрезмерно волноваться о разных вещах" checked={checked6} setChecked={setChecked6} />
        <Block text="Моя импульсивность доставляют мне много неприятностей" checked={checked7} setChecked={setChecked7} />
        <Block text="Я артистичен и люблю привлекать к себе внимание" checked={checked8} setChecked={setChecked8} />
        <Block text="Я реалист" checked={checked9} setChecked={setChecked9} />
        <Block text="Я высказываю свое мнение, не смотря на реакцию окружающих" checked={checked10} setChecked={setChecked10} />
        <Block text="Я люблю факты и детали" checked={checked11} setChecked={setChecked11} />
      </div>
    </>
  );
}
export default GabaPersonality;
