/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import '../testcontent.css';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SerotonineMemory from './Serotonine/SerotonineMemory';
import SerotoninePhysical from './Serotonine/SerotoninePhysical';
import SerotonineCharacter from './Serotonine/SerotonineCharacter';
import { serotonineslice } from '../../../store/slices/serotonineSlice';
import SerotoninePersonality from './Serotonine/SerotoninePersonality';
import ElevateAppBar from './Sticky';
import TestStepper from '../UI/Teststepper';
import NextButtonBlock from '../UI/Testnextblock';
import showRedirectModal from '../../../components/checkmodal/showRedirectModal';

const steps = ['СЕРОТОНИН - память и внимание', 'СЕРОТОНИН - Физиология', 'СЕРОТОНИН - Характер', 'СЕРОТОНИН - Личность'];

function Partonecontent4() {
  showRedirectModal();
  const [serotonineresult, setSerotonineresult] = useState(0);
  const [serotonineresult2, setSerotonineresult2] = useState(0);
  const [serotonineresult3, setSerotonineresult3] = useState(0);
  const [serotonineresult4, setSerotonineresult4] = useState(0);
  const serotoninesum = serotonineresult + serotonineresult2 + serotonineresult3 + serotonineresult4;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(serotonineslice(serotoninesum));
  });

  const dopaminecount = useSelector((state) => state.dopaminecounter.value);
  const acetylcholinecount = useSelector((state) => state.acetylcholinecounter.value);
  const gabacount = useSelector((state) => state.gabacounter.value);
  const serotoninecount = useSelector((state) => state.serotoninecounter.value);

  useEffect(() => {
    sessionStorage.setItem('dgraph', JSON.stringify(dopaminecount));
    sessionStorage.setItem('agraph', JSON.stringify(acetylcholinecount));
    sessionStorage.setItem('ggraph', JSON.stringify(gabacount));
    sessionStorage.setItem('sgraph', JSON.stringify(serotoninecount));
  });
  const neuroarray = [
    {
      num: dopaminecount,
      link: '/resultdopamine',
    },
    {
      num: acetylcholinecount,
      link: '/resultacetylcholine',
    },
    {
      num: gabacount,
      link: '/resultgaba',
    },
    {
      num: serotoninecount,
      link: '/resultserotonine',
    },
  ];
  const check = neuroarray.map((e) => Math.max(e.num));
  const check2 = Math.max(...check);
  const positiveSum = function positiveSum(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].num === check2) { return array[i].link; }
    }
  };
  const resultlink = positiveSum(neuroarray);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 10; // если нужно ставить OPTIONAL для возможности пропустить

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const navigate = useNavigate();

  if (activeStep === steps.length) {
    setTimeout(() => {
      navigate(resultlink);
    }, 4000);
  }

  return (
    <>
      <ElevateAppBar />
      <Box sx={{ width: '100%' }} className="partonebox">
        <TestStepper activeStep={activeStep} isStepOptional={isStepOptional} isStepSkipped={isStepSkipped} steps={steps} />
        { activeStep === steps.length && (
        <>
          <div className="partonenext-content">
            <div className="partonenext-text">
              Данные по серотонину собраны. Результат почти готов.
            </div>
          </div>
          <div className="partone-loading">
            <img src="img/result.svg" alt="loading" />
          </div>
          {/* <div className="next-link-content-result">
            <Link to={resultlink} className="next-link-result">РЕЗУЛЬТАТ</Link>
          </div> */}
        </>
        )}
        { activeStep === 0 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <SerotonineMemory setSerotonineresult={setSerotonineresult} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 1 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <SerotoninePhysical setSerotonineresult={setSerotonineresult2} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 2 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <SerotonineCharacter setSerotonineresult={setSerotonineresult3} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
        { activeStep === 3 && (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className="mainblock">
              <SerotoninePersonality setSerotonineresult={setSerotonineresult4} className="dopamineblockcontent" />
            </div>
            {/* {activeStep + 1} ///if we need display page number */}
          </Typography>
          <NextButtonBlock
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            isStepOptional={isStepOptional}
            handleSkip={handleSkip}
            handleNext={handleNext}
          />
        </>
        )}
      </Box>

    </>
  );
}

export default Partonecontent4;
