/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Block from '../../UI/Block';

function DopamineCharacter({ setDopamineresult }) {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  useEffect(() => {
    setDopamineresult(checked + checked2);
  });

  return (
    <>
      <div className="block-name">ДОФАМИН - Характер</div>
      <div id="partone" className="main-partone">
        <Block text="Я стал хуже рассуждать" checked={checked} setChecked={setChecked} />
        <Block text="Я перестал принимать правильные решения" checked={checked2} setChecked={setChecked2} />
      </div>
    </>
  );
}
export default DopamineCharacter;
